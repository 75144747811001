import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import ProgressBar from "../ProgressBar";

const FormImage = ({ label, name, width, progress, progressVisible }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div className={`${width}`}>
      <p className="text-[#4D5959] text-left text-[14px]">{label}</p>
      <input
        placeholder={label}
        className="file-input file-input-accent file-input-sm w-full text-[14px] my-1"
        name={name}
        type="file"
        onChange={(e) => {
          setFieldValue(name, e.target.files[0]);
        }}
      ></input>
      {!!progress && <ProgressBar progressPercentage={progress}></ProgressBar>}
      <ErrorMessage
        className="text-[#e95f5f] text-left text-[14px]"
        name={name}
        component="p"
      />
    </div>
  );
};

export default FormImage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomToast from "../components/CustomToast";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";

import { getUserInformationService } from "../services/userService";
import { getLocalStorageItem } from "./LocalStorage";

const ProtectRouteForEachRole = (props) => {
  const navigate = useNavigate();
  const [isAllowed, setIsAllowed] = useState(false);

  const checkRole = async () => {
    const token = getLocalStorageItem();
    try {
      const { data } = await getUserInformationService(token);

      if (data) {
        if (data.data.role.id >= props.id) setIsAllowed(true);
        else {
          setIsAllowed(false);
          // navigate("/myProfile");
        }
      }
    } catch (error) {
      CustomToast("error", "Error occurred, Please try again!");
    }
  };
  useEffect(() => {
    checkRole();
  }, [isAllowed]);
  return (
    <div className="flex min-h-[50vh]">
      {isAllowed ? (
        props.children
      ) : (
        <div className="flex flex-1 flex-col justify-center items-center">
          <ErrorIcon color="error" sx={{ fontSize: 80 }}></ErrorIcon>
          <Typography fontSize={20} fontFamily={"cursive"}>
            This option is only available for members only
          </Typography>
        </div>
      )}
    </div>
  );
};
export default ProtectRouteForEachRole;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import CustomToast from "../components/CustomToast";
import Loader from "../components/Loader";
import PaymentTable from "../components/PaymentTable";
import Birr from "../assets/birr.png";
import { getLocalStorageItem } from "../helper/LocalStorage";
import {
  getUserInformationService,
  setWithDrawalPercentageService,
} from "../services/userService";
import { getPaymentInformationService } from "../services/agentService";
import ErrorMessage from "../components/ErrorMessage";
import {
  getPaymentStatusService,
  requestPaymentService,
} from "../services/paymentService";
import FormField from "../components/CustomForm/FormField";
import { Form, Formik } from "formik";
import CustomModal from "../components/CustomModal";
import FormButton from "../components/CustomForm/FormButton";
import { ErrorHandler } from "../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  bank: Yup.string().required().label("Bank"),
  bankAccountNo: Yup.string().required().label("Bank Account Number"),
});
const PaymentPage = () => {
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [percentage, setPercentage] = useState(50);
  const [payment, setPayment] = useState({});

  const getPayment = async () => {
    const token = await getLocalStorageItem();
    setLoading(true);
    try {
      const { data } = await getPaymentInformationService(token);
      if (data.data) setPayment(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const getPaymentStatus = async () => {
    const token = await getLocalStorageItem();
    setPaymentLoading(true);
    try {
      const { data } = await getPaymentStatusService(token);
      if (data) setPaymentStatus(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setPaymentLoading(false);
  };

  const setWithDrawalPercentage = async () => {
    setLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await setWithDrawalPercentageService(token, percentage);
      if (data.data) {
        CustomToast("success", data.data.message);
        setUser(data.data.user);
        getPayment();
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const getUserInformation = async () => {
    setUserLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await getUserInformationService(token);
      if (data.data) {
        setUser(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setUserLoading(false);
  };

  const handlePayment = async (values) => {
    setLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await requestPaymentService(
        {
          amount: payment?.availableValue,
          bank: values.bank,
          bankAccountNo: values.bankAccountNo,
        },
        token
      );

      if (data) {
        CustomToast("success", "Payment request sent!");
        setTimeout(() => {
          getPaymentStatus();
          setShowModal(false);
        }, 1000);
      }
    } catch (error) {
      setShowModal(false);
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getPayment();
    getUserInformation();
    getPaymentStatus();
  }, [user?.userId]);

  return (
    <div className="mb-5 px-2 sm:px-10 w-full">
      {loading || paymentLoading || userLoading ? (
        <Loader />
      ) : user ? (
        <div className="w-full">
          {user?.withdrawalPercentage < 0.5 && (
            <p className="bg-[#D04042] p-2 md:p-5 my-2 rounded-lg text-[white] font-mono text-[14px] sm:text-[16px] md:text-[18px]">
              Please set withdrawal percentage before requesting a payment. You
              can not change the withdrawal percentage later so please make sure
              to select wisely.
            </p>
          )}
          <div className="group relative flex justify-center">
            <div className="bg-slate-200 my-5 w-full rounded-md p-1 md:p-3 flex items-center justify-between">
              <div className="flex">
                <p className="text-slate-500 text-[14px] sm:text-[18px] font-semibold  ">
                  Total Values
                </p>
                <div className="flex ml-2 justify-center text-[14px] sm:text-[25px] md:text-[30px] lg:text-[30px]  items-center">
                  <p className="text-slate-500 text-[14px] sm:text-[18px]  font-semibold">
                    {payment?.totalPoint}
                  </p>
                  <img src={Birr} className="w-5 sm:w-3 md:w-5"></img>
                </div>
              </div>
              <p
                onClick={() => {
                  if (
                    payment?.availableValue >= payment?.minimumWithdrawalAmount
                  )
                    setShowModal(true);
                  else {
                    CustomToast(
                      "error",
                      "Sorry, you can't make a withdrawal with your current balance. Please buy additional share."
                    );
                  }
                }}
                className="text-[14px] sm:text-[18px] rounded-md p-1 cursor-pointer text-[white] bg-[green]"
              >
                Get paid
              </p>
            </div>

            <span className="hidden md:block pointer-events-none w-max absolute top-20 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-[#3f4153] px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:-top-3 before:-translate-y-1/2 before:border-4 before:border-transparent before:border-t-[#28325f] before:content-[''] group-hover:opacity-100">
              Total value represents the sum of points collected from inviting
              users or completed tasks multiplied by company factor
            </span>
          </div>

          {user?.withdrawalPercentage >= 0.5 ? (
            <p className="text-slate-500 text-[14px] sm:text-[18px] font-semibold  ">
              Withdrawal percentage = {100 - user?.withdrawalPercentage * 100}%
            </p>
          ) : (
            <>
              <p className="text-slate-500 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  ">
                Set share percentage
              </p>

              <p className="bg-[#ffde8b] p-1 md:p-5 rounded-lg text-[#626262] text-[14px] sm:text-[16px] md:text-[18px]">
                The difference between 100% and the selected percentage is your
                withdrawal percentage. Eg. If you select 70% then you can
                withdrawal 30% of your total value and the rest 70% will be
                summed up to your share.
              </p>
              <div className="my-1 lg:max-w-[700px]">
                <input
                  type="range"
                  min={50}
                  max={100}
                  value={percentage}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                  }}
                  className="range w-full"
                  step="10"
                />
                <div className="w-full flex justify-between text-xs px-2">
                  <p className="text-[black] font-semibold">50 %</p>
                  <p className="text-[black] font-semibold">60 %</p>
                  <p className="text-[black] font-semibold">70 %</p>
                  <p className="text-[black] font-semibold">80 %</p>
                  <p className="text-[black] font-semibold">90 %</p>
                  <p className="text-[black] font-semibold">100 %</p>
                </div>
              </div>
              <p
                onClick={setWithDrawalPercentage}
                className="bg-[green] cursor-pointer w-max p-2 rounded-md text-white"
              >
                Set percentage
              </p>
            </>
          )}
          <div className="my-5">
            <p className="text-slate-500 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  ">
              My Points
            </p>
            {payment ? (
              <table className="table-auto justify-center border-slate-300 w-[100%]">
                <thead className="bg-slate-500 w-[100%]">
                  <tr>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Total Points
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Retained Value
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Agent Point
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Minimum Withdrawal Amount
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Available Point
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] w-[20%] text-white p-2 border-slate-600">
                      Pending Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cursor-pointer  bg-slate-200 hover:bg-slate-300">
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment?.totalPoint}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment?.retainedValue}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px] ">
                      {payment?.V8}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px] ">
                      {payment?.minimumWithdrawalAmount}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px] ">
                      {payment?.availableValue}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px] ">
                      {payment?.PV}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <ErrorMessage error={"Couldn't fetch point information"} />
            )}
          </div>
          <p className="text-slate-500 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  ">
            Payment Details
          </p>

          <PaymentTable field={paymentStatus} />

          {!!paymentStatus?.length && (
            <>
              <p className="text-slate-500 mt-5 text-[14px] sm:text-[18px] md:text-[20px] font-semibold  ">
                Intrim Payment
              </p>

              <table className="table-auto justify-center border-slate-300 w-[100%]">
                <thead className="bg-slate-500 w-[100%]">
                  <tr>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Settled
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Pending
                    </th>
                    <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                      Settled + Pending + Last
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="cursor-pointer  bg-slate-200 hover:bg-slate-300">
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment?.settled}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {payment?.PV}
                    </td>
                    <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                      {parseInt(payment?.settled) + parseInt(payment?.PV)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {showModal && (
            <CustomModal
              onClose={() => setShowModal(false)}
              loading={loading}
              title={"GET PAID"}
            >
              <Formik
                initialValues={{
                  bank: "",
                  bankAccountNo: "",
                }}
                onSubmit={(values) => {
                  handlePayment(values);
                }}
                validationSchema={validationSchema}
              >
                {({ isSubmitting }) => (
                  <Form className="flex flex-col flex-wrap w-[100%]  items-center rounded-lg">
                    <FormField
                      label="Bank"
                      name="bank"
                      type="text"
                      placeholder="Bank"
                      width="w-[100%] px-5 my-1"
                    />
                    <FormField
                      width="w-[100%] px-5 my-1"
                      label="Bank Account Number"
                      name="bankAccountNo"
                      type="text"
                      placeholder="bankAccountNo"
                    />
                    <div className="w-[90%] my-2">
                      <p className="text-slate-500 text-[14px] sm:text-[16px] font-semibold  ">
                        Requesting payment of {payment?.availableValue} ETB
                      </p>
                    </div>

                    <FormButton title={"Request"} isSubmitting={isSubmitting} />
                  </Form>
                )}
              </Formik>
            </CustomModal>
          )}
        </div>
      ) : (
        <ErrorMessage error={"Couldn't fetch user information"} />
      )}
    </div>
  );
};

export default PaymentPage;

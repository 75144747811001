import React from "react";
import { useState, useRef, useEffect } from "react";
import { BASE_URL } from "../config/config";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
const CustomCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  //change to the next image
  const nextImage = () => {
    if (currentImageIndex === images.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  //change to the previous image
  const prevImage = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(images.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  //change to the next image after 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      // call nextImage every 3 seconds
      nextImage();
    }, 5000);

    //clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  return (
    <div className="relative">
      <div className="w-full h-full ">
        <img
          src={`${BASE_URL}${images[currentImageIndex]?.image}`}
          style={{ width: "100%", height: 600 }}
          alt=""
          className="object-cover"
        />
      </div>
      <div className="flex w-full justify-center p-1.5">
        <AiOutlineArrowLeft
          onClick={prevImage}
          className="text-2xl text-white mx-2 rounded-full bg-black bg-opacity-50 w-[40px] h-[40px] hover:bg-opacity-100 focus:outline-none"
        />
        <AiOutlineArrowRight
          onClick={nextImage}
          className="text-2xl text-white mx-2 rounded-full bg-black bg-opacity-50 w-[40px] h-[40px] hover:bg-opacity-100 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default CustomCarousel;

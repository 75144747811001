import { ErrorMessage, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import FormButton from "../components/CustomForm/FormButton";
import FormContainer from "../components/CustomForm/FormContainer";
import FormDatePicker from "../components/CustomForm/FormDatePicker";
import FormDropDown from "../components/CustomForm/FormDropDown";
import FormImage from "../components/CustomForm/FormImage";
import CustomToast from "../components/CustomToast";
import FormField from "../components/CustomForm/FormField";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { getLocalStorageItem } from "../helper/LocalStorage";
import { getAllBankService } from "../services/bankService";
import { addFieldService, getAllFieldService } from "../services/fieldService";
import { getShareForRoleService } from "../services/shareService";
import { registerPremiumUserService } from "../services/userService";
import CustomModal from "../components/CustomModal";
import { ErrorHandler } from "../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Father Name"),
  grandFatherName: Yup.string().required().label("Grand Father Name"),
  email: Yup.string().email().required().label("Email"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  password: Yup.string().required().label("Password"),
  university: Yup.string().required().label("Highest Education Level"),
  birthDate: Yup.date().required().label("Birth Date"),
  tinNumber: Yup.number()
    .test(
      "len",
      "Tin number must be 10 digits",
      (val) => val?.toString().length == 10
    )
    .required()
    .label("Tin Number"),
  profilePicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only images and pdf is allowed",
      (value) =>
        value &&
        ["image/png", "image/jpg", "image/jpeg", "pdf"].includes(value.type)
    )
    .label("Profile Picture"),
  idPicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only images and pdf is allowed",
      (value) =>
        value &&
        ["image/png", "image/jpg", "image/jpeg", "pdf"].includes(value.type)
    )
    .label("Id/ Passport Picture "),
  field_id: Yup.string().required().label("Field"),
  motherFullName: Yup.string().required().label("Mother Full Name"),
  paymentAmount: Yup.number().required().label("Payment Amount"),
  paymentBank: Yup.string().required().label("Bank"),
  paymentDate: Yup.date().required().label("Payment Date"),
  paymentReferenceNo: Yup.string().required().label("Payment Reference Number"),
  referal: Yup.string().required().label("This field"),
  referalCode: Yup.string().required().label("Referal code"),
  serialKey: Yup.number()
    .test("len", "Key must be 10 digits", (val) => val?.toString().length == 10)
    .required()
    .label("Key"),
});

const PremiumUser = () => {
  const premiumUser = {
    firstName: "",
    lastName: "",
    grandFatherName: "",
    email: "",
    phoneNumber: "",
    password: "",
    country: "",
    state: "",
    city: "",
    university: "",
    motherFullName: "",
    birthDate: "",
    profilePicture: "",
    idPicture: "",
    field_id: "",
    tinNumber: "",
    paymentBank: "",
    paymentAmount: "",
    paymentDate: "",
    paymentReferenceNo: "",
    referal: "",
    referalCode: "",
    serialKey: "",
  };

  const [loading, setLoading] = useState(false);
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);

  const [bank, setBank] = useState([]);
  const [field, setField] = useState([]);
  const [share, setShare] = useState([]);

  useEffect(() => {
    getAllBank();
    getShareInformation();
    getField();
    return () => {
      setBank([]);
      setShare({});
      setField([]);
    };
  }, []);
  const token = getLocalStorageItem();

  const getAllBank = async () => {
    setLoading(true);
    try {
      const { data } = await getAllBankService(token);
      if (data.data) setBank(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const getShareInformation = async () => {
    setLoading(true);
    try {
      const { data } = await getShareForRoleService(7, token);
      if (data.data) setShare(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const getField = async () => {
    setLoading(true);
    try {
      const { data } = await getAllFieldService(token);
      if (data) setField(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const handleAdditionalField = async (values) => {
    try {
      setFieldLoading(true);
      const { data } = await addFieldService(values, token);
      if (data.data) {
        CustomToast("success", data.data);
        setModal(false);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }

    setFieldLoading(false);
  };

  const handleRegister = async (values) => {
    const token = getLocalStorageItem();
    setPremiumLoading(true);
    const formData = new FormData();
    if (values.referal == "others") delete values.serialKey;
    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    try {
      const { data } = await registerPremiumUserService(
        formData,
        token,
        (progress) => {
          setProgressVisible(true);
          setProgress(Math.ceil(progress * 100));
          setProgressVisible(false);
        }
      );
      if (data?.data) {
        CustomToast("success", data.data);
        setProgress(0);
      }
    } catch (error) {
      CustomToast("error", error.response?.data?.message || error?.message);
    }
    setPremiumLoading(false);
  };
  return (
    <div className="">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full  items-center">
          <div className="flex justify-center">
            <p className="bg-[#ffde8b] w-[90%] lg:w-max p-2.5 mt-2 rounded-lg text-[#666262]">
              If you don't find the field you are looking for please add your
              field through{" "}
              <span
                onClick={() => setModal(true)}
                className="underline text-blue-400 cursor-pointer"
              >
                this{" "}
              </span>
              and wait until the admin approves the field
            </p>
          </div>
          <Formik
            initialValues={premiumUser}
            onSubmit={(values) => {
              handleRegister(values);
            }}
            // validationSchema={validationSchema}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form
                className={`flex my-5 flex-wrap w-[90%] lg:w-[70%] shadow-lg rounded-lg`}
              >
                <div className="w-full justify-between flex flex-wrap">
                  <FormField
                    type={"text"}
                    name={"firstName"}
                    label={"First Name"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"lastName"}
                    label={"Father Name"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"grandFatherName"}
                    label={"Grand Father Name"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    // type={"email"}
                    name={"email"}
                    label={"Email"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"phoneNumber"}
                    label={"Phone Number"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"country"}
                    label={"Country"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"state"}
                    label={"State"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"text"}
                    name={"city"}
                    label={"City"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  {/* <FormField
                      width={"w-[100%] sm:w-[80%] md:w-[40%]"}
                      type={"text"}
                      name={"university"}
                      label={"University"}
                      />{" "}
                      <FormField
                      width={"w-[100%] sm:w-[80%] md:w-[40%]"}
                      type={"text"}
                      name={"field"}
                      label={"Field"}
                    />
                    <FormDatePicker
                      width={"w-[100%] sm:w-[80%] md:w-[40%]"}
                      name={"yearGraduated"}
                      title={"Year Graduated"}
                    ></FormDatePicker> */}
                  <FormField
                    type={"password"}
                    name={"password"}
                    label={"Password"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormDropDown
                    data={[
                      {
                        id: "Primary School",
                        name: "Primary School",
                      },
                      {
                        id: "Secondary School",
                        name: "Secondary School",
                      },
                      {
                        id: "diploma",
                        name: "Diploma",
                      },
                      {
                        id: "undergraduate",
                        name: "Undergraduate",
                      },
                      {
                        id: "postgraduate",
                        name: "Postgraduate",
                      },
                    ]}
                    label="Highest Education Level"
                    name="university"
                    width={"w-[90%] md:w-[50%] md:px-5"}
                  />

                  <FormField
                    type={"date"}
                    name={"birthDate"}
                    label={"Birth Date"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  {/* <FormDatePicker
                    width={"w-[100%] sm:w-[80%] md:w-[40%]"}
                    name={"birthDate"}
                    title={"Birth Date"}
                  ></FormDatePicker> */}
                  <FormField
                    type={"number"}
                    name={"tinNumber"}
                    label={"Tin Number"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormImage
                    progressVisible={progressVisible}
                    progress={progress}
                    label={"Profile Picture"}
                    name={"profilePicture"}
                    width={"w-[90%] md:w-[50%] md:px-5 my-1"}
                  ></FormImage>
                  <FormImage
                    progressVisible={progressVisible}
                    progress={progress}
                    label={"Id /Passport Picture"}
                    name={"idPicture"}
                    width={"w-[90%] md:w-[50%] md:px-5 my-1"}
                  ></FormImage>

                  <FormDropDown
                    label={"Field"}
                    name={"field_id"}
                    width={"w-[90%] md:w-[50%] md:px-5 my-1"}
                    data={field}
                  />
                  <FormField
                    type={"text"}
                    name={"motherFullName"}
                    label={"Mother Full Name"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <div className={"w-[90%] md:w-[50%] md:px-5 my-1"}>
                    <div className="w-full">
                      <label
                        htmlFor="paymentAmount"
                        className="block text-left mb-2 text-[#4D5959]"
                      >
                        Share Amount
                      </label>
                      <select
                        onChange={(e) => {
                          setFieldValue("paymentAmount", e.currentTarget.value);
                        }}
                        id="paymentAmount"
                        className="bg-[#f1f1f1] outline-[#99d5e9] text-gray-900 rounded-lg w-full md:p-2.5 "
                      >
                        <option value={null}>Select</option>
                        {/* {share?.map((shares) => ( */}
                        <option value={share.amount}>
                          {share?.amount} ETB for {share?.percentage}%
                        </option>
                        {/* )) */}
                      </select>
                      <ErrorMessage
                        className="text-[red] text-left"
                        name="paymentAmount"
                        component="p"
                      />
                    </div>
                  </div>

                  <FormDropDown
                    label={"Bank"}
                    name={"paymentBank"}
                    width={"w-[90%] md:w-[50%] md:px-5 my-1"}
                    data={bank}
                  />

                  <FormField
                    type={"text"}
                    name={"paymentReferenceNo"}
                    label={"Payment Transaction Number"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    type={"date"}
                    name={"paymentDate"}
                    label={"Date of Payment"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  {/* <FormDatePicker
                    width={"w-[90%] md:w-[50%] md:px-5 my-3"}
                    name={"paymentDate"}
                    title={"Date of Payment"}
                  ></FormDatePicker> */}

                  <div className={"w-[90%] md:w-[50%] md:px-5 my-1"}>
                    <label
                      htmlFor="referal"
                      className="block text-left mb-2 text-[#4D5959]"
                    >
                      How do you hear about us?
                    </label>
                    <select
                      onChange={(e) => {
                        if (e.currentTarget.value == "others") {
                          setFieldValue("referalCode", null);
                          setFieldValue("referal", e.currentTarget.value);
                        } else {
                          setFieldValue("referal", e.currentTarget.value);
                        }
                      }}
                      id="referal"
                      className="bg-gray-50 outline-[#99d5e9] text-gray-900 rounded-lg w-full p-2.5 "
                    >
                      <option value={null}>Select</option>
                      <option value={"others"} className="">
                        Other
                      </option>
                      <option value={"agent"} className="">
                        Agent
                      </option>
                    </select>
                    <ErrorMessage
                      className="text-[red] text-left"
                      name="referal"
                      component="p"
                    />
                  </div>
                  {values.referal == "agent" && (
                    <div className="flex flex-col md:flex-row justify-center items-center w-[100%]">
                      <FormField
                        isLogin={true}
                        label="Referal Code for the agent"
                        name="referalCode"
                        type="text"
                        width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                      />

                      <FormField
                        isLogin={true}
                        label="Serial Key"
                        name="serialKey"
                        type="text"
                        width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                      />
                    </div>
                  )}
                </div>
                <FormButton
                  title={"Register Premium User"}
                  loading={premiumLoading}
                />
                {/* <div className="flex justify-center w-full">
                  <button
                    type="submit"
                    className="bg-[#232536] self-center px-5 my-10 py-2 xs:w-[90%] md:w-[40%] lg:w-[30%] rounded-lg text-white"
                    disabled={isSubmitting}
                  >
                    Register Premium User
                  </button>
                </div> */}
              </Form>
            )}
          </Formik>
          {modal && (
            <CustomModal onClose={() => setModal(false)} loading={fieldLoading}>
              <FormContainer
                initialValues={{ name: "" }}
                width={"w-[100%]"}
                handleSubmit={handleAdditionalField}
              >
                <FormField
                  name={"name"}
                  isLogin={true}
                  label={"Field"}
                  type={"text"}
                ></FormField>
                <FormButton
                  handleClick={handleAdditionalField}
                  title="Add Field"
                />
              </FormContainer>
            </CustomModal>
          )}
        </div>
      )}
    </div>
  );
};

export default PremiumUser;

import axios from "axios";
import { URL } from "../config/config";

export const addCurrentJobService = async (value, token, onUploadProgress) => {
  return await axios.post(`${URL}currentJob`, value, {
    headers: {
      "x-auth-token": token,
    },
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};

export const getCurrentJobService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}currentJob?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addCompletedService = async (value, token, onUploadProgress) => {
  return await axios.post(`${URL}completedService`, value, {
    headers: {
      "x-auth-token": token,
    },
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};

export const getCompletedService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}completedService?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addSkillService = async (value, token) => {
  return await axios.post(`${URL}skill`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getSkillService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}skill?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addVolunteerService = async (value, token) => {
  return await axios.post(`${URL}volunteer`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getVolunteerService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}volunteer?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const addRoleModelService = async (value, token) => {
  return await axios.post(`${URL}roleModel`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getRoleModelService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}roleModel?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const addEmergencyService = async (value, token) => {
  return await axios.post(`${URL}emergencyContact`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getEmergencyService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}emergencyContact?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const addPersonalInfoService = async (value, token) => {
  return await axios.post(`${URL}personalInformation`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getPersonalInfoService = async (token, query) => {
  let queries = "";
  if (query) {
    const keys = Object.keys(query);
    queries = keys.map((key) => `${key}=${query[key]}`).join("&");
  }
  return await axios.get(`${URL}personalInformation?${queries}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

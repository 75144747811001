import React from "react";

function ListInformation({ label, name, value, onChange, disabled, type }) {
  return (
    <div className="flex flex-col w-[100%] md:w-[45%] my-2">
      <p className="text-left text-[14px] md:text-[16px] text-slate-500">
        {label}
      </p>
      <input
        disabled={disabled}
        onChange={(e) => onChange(e)}
        name={name}
        type={type ? type : null}
        className={`text-left outline-[lightblue] ${
          disabled ? "bg-[#ede6e6]" : "bg-slate-200"
        } rounded-md text-[14px] md:text-[16px] p-[5px] md:p-2`}
        value={value}
      />
    </div>
  );
}

export default ListInformation;

import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';

const UnderReview = () => {
  return (
    <div className="flex flex-1 flex-col justify-center items-center">
      <ReportProblemIcon
        color="warning"
        sx={{ fontSize: 80 }}
      ></ReportProblemIcon>
      <Typography fontSize={20} fontFamily={"cursive"}>
        Your profile is under review
      </Typography>
    </div>
  );
}

export default UnderReview
export const ErrorHandler = (error) => {
  const errors = [];
  if (error.response) {
    if (error.response.data.errors)
      errors.push({
        message: error.response.data.errors,
        type: error.response?.status,
      });
    else if (error.response.data.message)
      errors.push({
        message: error.response.data.message,
        type: error.response?.status,
      });
    else errors.push({ message: "Unknown Error!", type: undefined });
  } else if (error.request) {
    errors.push({ message: "Connection Error!", type: error.request?.status });
  } else {
    errors.push({ message: "Unknown Error!", type: undefined });
  }
  return errors;
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectAgentRoute from "../helper/ProtectAgentRoute";
import AddFieldPage from "../pages/AddFieldPage";
import AdditionalInformation from "../pages/AdditionalInformation/AdditionalInformation";
import ChangePassword from "../pages/ChangePassword";
import EditProfilePage from "../pages/EditProfilePage";
import EventsPage from "../pages/EventsPage";
import InvitedUser from "../pages/InvitedUser";
import MyProfile from "../pages/MyProfile";
import MyStatus from "../pages/MyStatus";
import PaymentPage from "../pages/PaymentPage";
import PremiumUser from "../pages/PremiumUser";
import ProfilePage from "../pages/ProfilePage";
import SignUpForG2 from "../pages/SignUp/SignUpForG2";
import SignUpForG3 from "../pages/SignUp/SignUpForG3";
import SignUpForG4 from "../pages/SignUp/SignUpForG4";
import SignUpForG5 from "../pages/SignUp/SignUpForG5";
import SignUpForG6 from "../pages/SignUp/SignUpForG6";
import SignUpForG7 from "../pages/SignUp/SignUpForG7";
import Header from "./Header";
import ProtectRouteForEachRole from "../helper/ProtectRouteForEachRole";
import AddUser from "../pages/AddUsers/AddUser";
import AssignKeyToUser from "../pages/AssignKeyToUser/AssignKeyToUser";
import SideMenu from "./SideMenu/SideMenu";
import UnderReview from "./common/UnderReview";

const ClientRoute = () => {
  return (
    <>   
      <Routes>
        <Route path="/signupForG2" element={<SignUpForG2 />}></Route>
        <Route path="/signupForG3" element={<SignUpForG3 />}></Route>
        <Route path="/signupForG4" element={<SignUpForG4 />}></Route>
        <Route path="/signupForG5" element={<SignUpForG5 />}></Route>
        <Route path="/signupForG6" element={<SignUpForG6 />}></Route>
        <Route path="/signupForG7" element={<SignUpForG7 />}></Route>
        {/* <Route path="/myProfile" element={<MyProfile />}></Route> */}
        <Route path="/profilePage" element={<ProfilePage />}></Route>
        <Route path="/editProfile" element={<EditProfilePage />}></Route>
        <Route path="/addField" element={<AddFieldPage />}></Route>
        <Route path="/changePassword" element={<ChangePassword />}></Route>
        <Route
          path="/myStatus"
          element={
            <ProtectRouteForEachRole id={4}>
              <MyStatus />
            </ProtectRouteForEachRole>
          }
        ></Route>
        <Route path="/invitedUser" element={<InvitedUser />}></Route>
        <Route
          path="/events"
          element={
            <>
              <EventsPage />
            </>
          }
        ></Route>
        <Route
          path="/additionalInformation"
          element={<AdditionalInformation />}
        ></Route>
        <Route path="/assignKey" element={<AssignKeyToUser />}></Route>
        <Route
          path="/addUser"
          element={
            <ProtectAgentRoute>
              <AddUser />
            </ProtectAgentRoute>
          }
        ></Route>
        <Route path="/payment" element={<PaymentPage />}></Route>
        <Route path="/underReview" element={<UnderReview />}></Route>

        {/* <Route path="/currentJob" element={<CurrentJob />}></Route>
          <Route path="/completedService" element={<CompletedService />}></Route>
          <Route path="/skill" element={<Skill />}></Route>
          <Route path="/volunteer" element={<Volunteer />}></Route> 
          {/* <Route path="/roleModel" element={<RoleModel />}></Route> */}
      </Routes>
    </>
  );
};

export default ClientRoute;

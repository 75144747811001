import React, { useState } from "react";
import moment from "moment";

import { Paginate } from "../helper/paginate";
import Paginations from "../components/pagination";
import ErrorMessage from "./ErrorMessage";
const PaymentTable = ({ field }) => {
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (field.length === 0) return <ErrorMessage error={"No payment made yet"} />;

  let filteredField = field?.filter(
    (field) =>
      field?.amount?.match(new RegExp(filter, "i")) ||
      field?.bank?.match(new RegExp(filter, "i"))
  );
  const fields = Paginate(filteredField, currentPage, pageSize);
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center m-auto w-[100%]">
        <div className="flex justify-between">
          <div className="flex flex-row rounded-md bg-slate-500 my-3 place-self-start">
            <label
              className="text-lg text-white font-semibold p-1"
              htmlFor="filter"
            >
              Filter
            </label>
            <input
              type="text"
              name="filter"
              className="border border-slate-400 m-2 focus:outline-none rounded-sm focus:border-slate-500 focus:ring-1 focus:ring-slate-500"
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            ></input>
          </div>
        </div>
        <table className="table-auto justify-center border-slate-300 w-[100%]">
          <thead className="bg-slate-500 w-[100%]">
            <tr>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                No
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Amount
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Bank
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Account Number
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Transaction Number
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Date
              </th>
              <th className="border font-normal text-[12px] sm:text-[14px] md:text-[16px] text-white p-2 border-slate-600">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredField?.map((fields, index) => (
              <tr
                className="cursor-pointer  bg-slate-200 hover:bg-slate-300"
                key={index}
              >
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {index + 1}
                </td>
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {fields.amount}
                </td>
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {fields.bank}
                </td>
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {fields.bankAccountNo}
                </td>
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {fields.reference ? fields.reference : "pending payment"}
                </td>
                <td className="border border-slate-800 text-center text-slate-800 p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                  {fields.date
                    ? moment(fields.date).format("DD-MMM-YYYY")
                    : " pending payment"}
                </td>
                {fields.isPaid ? (
                  <td className="border bg-[#1e721e] px-5 border-slate-800 text-center text-white p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                    Paid
                  </td>
                ) : (
                  <td className="border bg-[#c12c07] text-white px-5 border-slate-800 text-center p-2 text-[12px] sm:text-[14px] md:text-[16px]">
                    Not Paid
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        <Paginations
          itemsCount={field.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        ></Paginations>
      </div>
    </div>
  );
};

export default PaymentTable;

import React from "react";
import { Formik, Form } from "formik";
import FormButton from "./FormButton";

const FormContainer = ({
  children,
  initialValues,
  handleSubmit,
  validationSchema,
  buttonTitle,
  width,
}) => {
  return (
    // <div className="flex flex-col justify-center items-center min-h-[calc(100vh-80px)]">
    // <div className="flex flex-col">
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <Form
            className={`flex flex-col bg-[#f8f8f8] drop-shadow-xl flex-wrap  ${width} items-center rounded-lg pt-5`}
          >
            {children}
            {/* <FormButton title={buttonTitle} onClick={handleSubmit} /> */}
          </Form>
        </>
      )}
    </Formik>
    // </div>
  );
};

export default FormContainer;

import React from "react";

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className="h-3 flex w-full justify-between items-center">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full rounded-md ${
          progressPercentage < 70 ? "bg-red-600" : "bg-green-600"
        }`}
      ></div>
      <p>{progressPercentage + "%"}</p>
    </div>
  );
};

export default ProgressBar;

import React from "react";
import Loader from "../Loader";

const FormButton = ({ title, isSubmitting, handleClick, loading }) => {
  return (
    <div className="m-2 w-max min-w-[100px] md:w-[25%] max-w-[200px]">
      {loading ? (
        <div className="rounded-md px-1 py-[1px] w-[100%] bg-slate-600">
          <Loader width={"20px"} height={"20px"} />
        </div>
      ) : (
        <button
          disabled={isSubmitting}
          type="submit"
          onClick={handleClick}
          className="btn btn-neutral w-[100%]"
        >
          {title}
        </button>
      )}
    </div>
  );
};

export default FormButton;

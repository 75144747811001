import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addCompletedService,
  getCompletedService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name of Customer"),
  phoneNo: Yup.string().required().label("CUstomer Phone Number"),
  email: Yup.string()
    .email({ tlds: { allow: ["com", "net", "org", "et"] } })
    .required()
    .label("Customer Email"),
  country: Yup.string().required().label("Customer Country"),
  state: Yup.string().required().label("Customer State"),
  city: Yup.string().required().label("Customer City"),
  serviceProvided: Yup.string().required().label("Service Provided"),
  agreementPicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only image is allowed",
      (value) =>
        !value ||
        (value &&
          ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
            value.type
          ))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 2 Mega Bytes",
      (value) => !value || (value && value.size <= 2000000)
    )
    .label("Agreement Document"),
  agreementStartDate: Yup.date().required().label("Agreement start date"),
  agreementCompletionDate: Yup.date()
    .required()
    .label("Agreement completion date"),
  serviceDuration: Yup.string().required().label("Service Duration"),
  amountInBirr: Yup.string().required().label("Amount in Birr"),
  recommendationPicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only image is allowed",
      (value) =>
        !value ||
        (value &&
          ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
            value.type
          ))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 2 Mega Bytes",
      (value) => !value || (value && value.size <= 2000000)
    )
    .label("Recommendation Document"),
  extensionPeriod: Yup.string().required().label("Extension Period"),
  totalEarnedAmount: Yup.string().required().label("Total earned amount"),
});

const CompletedService = ({ info, setInfo, index }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);

  const handleCompletedJob = async (values) => {
    setPostLoading(true);
    try {
      const formData = new FormData();
      Object.keys(values).forEach((item) => {
        formData.append(item, values[item]);
      });
      const token = await getLocalStorageItem();
      const { data } = await addCompletedService(
        formData,
        token,
        (progress) => {
          setProgressVisible(true);
          setProgress(Math.ceil(progress * 100));
          setProgressVisible(false);
        }
      );
      if (data) {
        const newArray = [...info];
        newArray[index].opened = false;
        setInfo(newArray);
        CustomToast("success", "Completed service added successfully");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setPostLoading(false);
  };

  const getCompletedJob = async () => {
    setLoading(true);
    const token = await getLocalStorageItem();
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const { data } = await getCompletedService(token, { user_id: user.id });
        if (data) setList(data);
      } else setList([]);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompletedJob();
  }, []);

  return (
    <div className="flex flex-col">
      {/* <p className="text-[#535353] w-full p-5 mt-5 bg-[#e2dfc9] text-[14px] sm:text-[18px] font-semibold  ">
        Completed Service
      </p> */}
      {list?.map((lists, index) => (
        <div key={index} className="px-2">
          <p>Customer {index + 1}</p>
          <div className="bg-[#f5e9dc] rounded-md  p-2 my-2">
            <p className="text-[14px]">{lists.name}</p>
            <p className="text-[14px]">{lists.phoneNo}</p>
            <p className="text-[14px]">{lists.email}</p>
          </div>
        </div>
      ))}
      {loading ? (
        <Loader />
      ) : list.length == 3 ? (
        <ErrorMessage
          error={"You have submitted the required number of completed service"}
        />
      ) : (
        <div className="flex flex-col my-2 ">
          {postLoading && <Loader />}
          <FormContainer
            width="w-[100%]"
            handleSubmit={handleCompletedJob}
            initialValues={{
              name: "",
              phoneNo: "",
              email: "",
              country: "",
              state: "",
              city: "",
              serviceProvided: "",
              agreementPicture: "",
              agreementStartDate: "",
              agreementCompletionDate: "",
              serviceDuration: "",
              amountInBirr: "",
              recommendationPicture: "",
              extensionPeriod: "",
              totalEarnedAmount: "",
            }}
            validationSchema={validationSchema}
          >
            <div className="flex flex-wrap w-full ">
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer Name"
                name="name"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer Contact Tel"
                name="phoneNo"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer Email"
                name="email"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer Country"
                name="country"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer State"
                name="state"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Customer City"
                name="city"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Service Provided"
                name="serviceProvided"
                isLogin={false}
                type="text"
              />

              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Service Duration in Days"
                name="serviceDuration"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Agreed Amount in Birr"
                name="amountInBirr"
                isLogin={false}
                type="text"
              />

              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Extension Period in days"
                name="extensionPeriod"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Total Earned Amount Birr"
                name="totalEarnedAmount"
                isLogin={false}
                type="text"
              />
              <div className="w-[100%] md:w-[50%] my-1">
                <p className="text-[#4D5959] my-2 px-5">Agreement Start Date</p>
                <FormDatePicker
                  name="agreementStartDate"
                  title="Agreement Start Date"
                  width="w-[100%] md:w-[90%] px-5"
                />
              </div>
              <div className="w-[100%] md:w-[50%] my-1">
                <p className="text-[#4D5959] my-2 px-5">
                  Agreement Completion Date
                </p>
                <FormDatePicker
                  name="agreementCompletionDate"
                  title="Agreement Completion Date"
                  width="w-[100%] md:w-[90%] px-5 my-1"
                />
              </div>
              <FormImage
                progress={progress}
                progressVisible={progressVisible}
                label={"Upload Recommendation"}
                name="recommendationPicture"
                width={"w-[100%] md:w-[50%] px-5 my-1"}
              />
              <FormImage
                progress={progress}
                progressVisible={progressVisible}
                label={"Agreement Document"}
                name="agreementPicture"
                width={"w-[100%] md:w-[50%] px-5 my-1"}
              />
            </div>
            <FormButton title={"Submit"} />
          </FormContainer>
        </div>
      )}
    </div>
  );
};

export default CompletedService;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import CustomToast from "../../components/CustomToast";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addPersonalInfoService,
  getPersonalInfoService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { Field, setIn } from "formik";
import { AiOutlineDelete } from "react-icons/ai";
import ErrorMessage from "../../components/ErrorMessage";

const validationSchema = Yup.object().shape({
  religion: Yup.string().label("Religion"),
  hobby: Yup.string().label("Hobby"),
  entertainment: Yup.string().label("Entertainment"),
  bloodGroup: Yup.string().label("Blood Group"),
  age: Yup.number().label("Age"),
  allergies: Yup.string().label("Allergies"),
  medicalCase: Yup.string().label("Medical Case"),
  disability: Yup.string().label("Disability"),
  martialStatus: Yup.string().label("Martial Status"),
  kidsName: Yup.array()
    .min(0, "Please enter at least one kid's name")
    .of(Yup.string().label("Kid's name"))
    .label("Kids Name"),
});
const PersonalInformation = ({
  data: personal_info,
  setData,
  index: index_menu,
}) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    gender: "",
    age: 18,
    bloodGroup: "",
    religion: "",
    entertainment: "",
    allergies: "",
    medicalCase: "",
    disability: "",
    martialStatus: "",
    kidsName: [],
  });
  const [error, setError] = useState("");
  const [kids, setKidsName] = useState([]);

  const handleSubmit = async (values) => {
    setLoading(true);

    let removeEmpty = kids?.filter((item) => item != "");
    values.kidsName = [...removeEmpty, ...info?.kidsName];
    values.id = info?.id;
    try {
      const token = await getLocalStorageItem();
      const { data } = await addPersonalInfoService(values, token);
      if (data) {
        CustomToast("success", "Personal Information added successfully");
        const newArray = [...personal_info];
        newArray[index_menu].opened = false;
        setData(newArray);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const handleDelete = async (index) => {
    setLoading(true);
    let temp = [...info.kidsName];
    temp.splice(index, 1);
    let kidsName = { kidsName: [...temp], id: info?.id };
    try {
      const token = await getLocalStorageItem();
      const { data } = await addPersonalInfoService(kidsName, token);
      if (data) {
        CustomToast("success", "Kids information updated");
        const newArray = [...personal_info];
        newArray[index_menu].opened = false;
        setData(newArray);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const getPersonalInfo = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getPersonalInfoService(token, {
          user_id: user.id,
        });
        if (data.length) {
          setInfo(data[0]);
        }
      } else setInfo({});
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getPersonalInfo();
  }, []);

  const handleChange = (value, index) => {
    setError("");
    let temp = [...kids];
    temp[index] = value;
    setKidsName(temp);
  };

  return (
    <div className="flex flex-col my-2 ">
      {loading ? (
        <Loader />
      ) : (
        <FormContainer
          width="w-[100%]"
          handleSubmit={handleSubmit}
          initialValues={{
            gender: info?.gender ?? "",
            age: info?.age ?? 18,
            bloodGroup: info?.bloodGroup ?? "",
            religion: info?.religion ?? "",
            entertainment: info?.entertainment ?? "",
            allergies: info?.allergies ?? "",
            medicalCase: info?.medicalCase ?? "",
            disability: info?.disability ?? "",
            martialStatus: info?.martialStatus ?? "",
            kidsName: info?.kidsName ?? [],
          }}
          validationSchema={validationSchema}
        >
          <div className="flex flex-wrap w-full ">
            <FormDropDown
              data={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
              label="Gender"
              width="w-[100%] md:w-[50%] px-5 my-1"
              name="gender"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Age"
              name="age"
              type="number"
            />
            <FormDropDown
              data={[
                { id: "A+", name: "A+" },
                { id: "A-", name: "A-" },
                { id: "B+", name: "B+" },
                { id: "B-", name: "B-" },
                { id: "O+", name: "O+" },
                { id: "O-", name: "O-" },
                { id: "AB+", name: "AB+" },
                { id: "AB-", name: "AB-" },
              ]}
              label="Blood Group"
              width="w-[100%] md:w-[50%] px-5 my-1"
              name="bloodGroup"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Religion"
              name="religion"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Hobby"
              name="entertainment"
              type="text"
            />

            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Allergies"
              name="allergies"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Medical Case"
              name="medicalCase"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Disability"
              name="disability"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] px-5 my-1"
              label="Martial Status / Fiance Name"
              name="martialStatus"
              type="text"
            />

            <div className="w-[100%] md:w-[50%] px-5 my-1">
              <p className="text-[#4D5959] text-[14px] md:text-[16px] text-left">
                Kids Name
              </p>

              {kids.map((items, index) => (
                <div key={index} className="flex my-2">
                  <div className="flex flex-col">
                    <Field
                      value={items}
                      className="w-full  outline-[#99d5e9] rounded-md text-[14px] md:text-[16px] p-[5px] md:p-2 bg-[#EFF0F2]"
                      name="kidsName"
                      placeholder="Kids Name"
                      onChange={(e) => handleChange(e.target.value, index)}
                    />
                    {error && <ErrorMessage error={error} />}
                  </div>

                  <button
                    type="button"
                    className="bg-[#ee491f] h-max w-[30%] max-w-[100px] text-[12px] sm:text[14px] md:text-[16px] rounded-md p-1.5 text-white mx-1"
                    onClick={() => {
                      setError("");
                      let temp = [...kids];
                      temp.splice(index, 1);
                      setKidsName(temp);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-[orange] text-[14px] md:text-[16px] rounded-md p-1.5 text-white mx-1"
                onClick={() => {
                  if (kids[kids.length - 1] == "")
                    setError("Kids name is require");
                  else setKidsName([...kids, ""]);
                }}
              >
                Add Kid Name
              </button>

              <div className="w-[100%]">
                {info?.kidsName?.map((items, index) => (
                  <div key={index} className="flex items-center w-full">
                    <p className="bg-slate-200 px-5 w-full my-1">{items}</p>
                    <AiOutlineDelete
                      type="button"
                      size={"25px"}
                      color="red"
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <FormButton title={"Submit"} />
        </FormContainer>
      )}
    </div>
  );
};

export default PersonalInformation;

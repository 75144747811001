import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdScore, MdSupervisedUserCircle } from "react-icons/md";
import { getLocalStorageItem } from "../helper/LocalStorage";
import CustomToast from "../components/CustomToast";
import {
  fetchInvitedUserLengthInEachRoleService,
  fetchInvitedUserWithValueService,
} from "../services/agentService";
import Loader from "../components/Loader";
import ErrorMessage from "../components/ErrorMessage";
import { getUserInformationService } from "../services/userService";
import { ErrorHandler } from "../helper/ErrorHandler";

function MyStatus() {
  const [totalSum, setTotalSum] = useState(0);
  const navigate = useNavigate();

  const [scoreValue, setScoreValue] = useState({
    name: "Score-Value",
    value: "",
    icon: (
      <MdScore color="orange" fontSize="inherit" style={{ fontSize: "50px" }} />
    ),
  });
  const [invitedUserLen, setInvitedUserLen] = useState({
    name: "Invited Users",
    value: "",
    icon: (
      <MdSupervisedUserCircle
        color="green"
        fontSize="inherit"
        style={{ fontSize: "50px" }}
      />
    ),
  });

  const [invitedUser, setInvitedUser] = useState([]);
  const [userLength, setUserLength] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [lengthLoading, setLengthLoading] = useState(false);

  useEffect(() => {
    getInvitedUser();
    getUserInformation();
    findLengthOfInvitedUserInEachRole();
    return () => {
      setInvitedUser([]);
      setUser({});
      setUserLength([]);
    };
  }, []);

  const getInvitedUser = async () => {
    setLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await fetchInvitedUserWithValueService(token);

      setInvitedUser(data?.data?.findReferalPoint);
      setInvitedUserLen({
        ...invitedUserLen,
        value: data?.data?.findReferalPoint?.length,
      });
      setScoreValue({
        ...scoreValue,
        value: data?.data?.findCurrentUser?.scoreValue,
      });
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  const getUserInformation = async () => {
    setUserLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await getUserInformationService(token);
      if (data.data) {
        setUser(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => {
        CustomToast("error", e.message);
      });
    }
    setUserLoading(false);
  };

  const findLengthOfInvitedUserInEachRole = async () => {
    setLengthLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await fetchInvitedUserLengthInEachRoleService(token);
      setUserLength(data.data);
      setTotalSum(data.totalPoint);
    } catch (error) {
      ErrorHandler(error).map((e) => {
        CustomToast("error", e.message);
      });
    }
    setLengthLoading(false);
  };

  return (
    <div className="w-full min-h-[calc(100vh-80px)]">
      {loading || lengthLoading || userLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between flex-col sm:flex-row w-full ">
            <h3 className="font-semibold text-center rounded-md bg-[green] my-1 p-2 text-white w-full sm:w-[40%] md:w-max text-[14px] md:text-[16px]">
              {user?.isAgent ? "Agent Dashboard" : "Status Dashboard"}
            </h3>
            {user?.role_id >= 5 && (
              <Link
                to="/payment"
                className="font-semibold rounded-md text-center bg-[brown] my-1 p-2 text-white w-full sm:w-[40%] md:w-max text-[14px] md:text-[16px]"
              >
                Watch Payment Detail
              </Link>
            )}
          </div>
          <div className="my-5 shadow-lg border-[1px] border-gray-400 rounded-lg h-max">
            <p className="border-b-2 border-slate-300 p-2 font-semibold text-slate-400">
              {user?.isAgent ? "Agent Performance" : "My Performance"}
            </p>
            <div className="flex flex-col justify-around md:flex-row items-center">
              <div className="my-5 text-center bg-white w-full p-5 md:p-0 rounded-lg md:bg-transparent">
                <h3 className="text-slate-400 font-semibold text-[14px] sm:text-[16px] md:text-[17px] lg:text-[18px]">
                  {scoreValue?.name}
                </h3>
                <div className="flex my-2 justify-center items-center">
                  {scoreValue?.icon}
                  <h3
                    className={`                 
                       font-semibold text-[20px] sm:text-[20px] md:text-[25px] lg:text-[35px]`}
                  >
                    {scoreValue?.value}
                  </h3>
                </div>
              </div>
              <div className="my-5 text-center bg-white w-full p-5 md:p-0 rounded-lg md:bg-transparent">
                <h3 className="text-slate-400 font-semibold text-[14px] sm:text-[16px] md:text-[17px] lg:text-[18px]">
                  {invitedUserLen?.name}
                </h3>
                <div className="flex my-2 justify-center items-center">
                  {invitedUserLen?.icon}
                  <h3
                    className={` font-semibold text-[20px] sm:text-[20px] md:text-[25px] lg:text-[35px]`}
                  >
                    {invitedUserLen?.value ? invitedUserLen?.value : "0"}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="my-5">
            <p className="text-slate-600 font-semibold text-[16px] lg:text-[18px]">
              Invited User
            </p>
            {invitedUser?.length ? (
              <table className="table-auto justify-center border-slate-300 w-[100%]">
                <thead className="bg-slate-500 w-[100%]">
                  <tr>
                    <th className="border w-[5%] text-white p-2 border-slate-600">
                      No
                    </th>
                    <th className="border w-[25%] text-white p-2 border-slate-600">
                      First Name
                    </th>
                    <th className="border w-[25%] text-white p-2 border-slate-600">
                      Last Name
                    </th>
                    <th className="border w-[15%] text-white p-2 border-slate-600">
                      Role
                    </th>
                    <th className="border w-[50%] text-white p-2 border-slate-600">
                      Point Collected
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invitedUser.map((invitedUsers, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer  bg-slate-200 hover:bg-slate-300"
                    >
                      <td className="border border-slate-800 text-left  text-slate-800 p-2 text-lg ">
                        {index + 1}
                      </td>
                      <td className="border border-slate-800 text-left  text-slate-800 p-2 text-lg ">
                        {invitedUsers?.user?.firstName}
                      </td>
                      <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                        {invitedUsers?.user?.lastName}
                      </td>
                      <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                        {invitedUsers?.user?.role?.name}
                      </td>
                      <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                        <p>
                          {invitedUsers?.lastPoint} points when{" "}
                          {invitedUsers?.user?.firstName} joined{" "}
                          {invitedUsers?.user?.role?.name}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <ErrorMessage
                error={
                  "You haven't earned any points or the invited user profile is under review"
                }
              />
            )}
          </div>
          <div className="my-5">
            <p className="text-slate-600 font-semibold text-[16px] lg:text-[18px]">
              Earned Points
            </p>
            {invitedUser?.length ? (
              <div className="w-full">
                <table className="table-auto justify-center border-slate-300 w-[100%]">
                  <thead className="bg-slate-500 w-[100%]">
                    <tr>
                      <th className="border w-[10%] text-white p-2 border-slate-600">
                        No
                      </th>
                      <th className="border w-[30%] text-white p-2 border-slate-600">
                        Role
                      </th>
                      <th className="border w-[30%] text-white p-2 border-slate-600">
                        Total number of invited user
                      </th>
                      <th className="border w-[30%] text-white p-2 border-slate-600">
                        Point
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userLength.map((ul, index) => (
                      <tr
                        onClick={() => {
                          ul.length > 0
                            ? navigate("/invitedUser", {
                                state: {
                                  isPremiumUser: index == 6 ? 1 : 0,
                                  role_id: index != 6 ? ul?.name?.slice(-1) : 7,
                                },
                              })
                            : CustomToast("err", "No user found in this role");
                        }}
                        key={index}
                        className="bg-slate-200 hover:bg-slate-300 cursor-pointer"
                      >
                        <td className="border border-slate-800 text-left  text-slate-800 p-2 text-lg ">
                          {index + 1}
                        </td>
                        <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                          {ul.name}
                        </td>
                        <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                          {ul.length}
                        </td>
                        <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                          {ul.point}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="border my-2 border-slate-800  text-center text-slate-800 p-2 font-semibold text-lg rounded-md w-full  ">
                  Total Points Earned {totalSum}
                </p>
              </div>
            ) : (
              <ErrorMessage
                error={
                  "You haven't earned any points or the invited user profile is under review"
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MyStatus;

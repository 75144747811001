import { Form, Formik } from "formik";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FormField from "../components/CustomForm/FormField";
import * as Yup from "yup";
import { loginService } from "../services/userService";
import CustomToast from "../components/CustomToast";
import { setLocalStorageItem } from "../helper/LocalStorage";
import CustomModal from "../components/CustomModal";
import { ErrorHandler } from "../helper/ErrorHandler";
import jwtDecode from "jwt-decode";
import AuthContext from "../context/AuthContext";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  password: Yup.string().required().label("Password"),
});
const LoginPage = forwardRef((props, ref) => {
  const {
    showModal,
    setShowModal,
    setShowModalForSignup,
    setShowModalForForgot,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const { data } = await loginService(values);
      if (data) {
        setLocalStorageItem(data.data);
        window.location = "/";
        setShowModal(false);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  return (
    <div>
      {showModal && (
        <CustomModal
          onClose={() => setShowModal(false)}
          loading={loading}
          ref={ref}
          title={"Login"}
        >
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => {
              handleLogin(values);
            }}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col flex-wrap w-[100%]  items-center rounded-lg">
                <FormField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  width={`w-[90%] md:px-5 my-1`}
                />
                <FormField
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  width={`w-[90%] md:px-5 my-1`}
                />
                <div className="flex flex-col justify-center my-5 items-center w-full">
                  <button
                    type="submit"
                    className={` bg-[#232536] px-10 py-2 xs:w-[90%] md:w-[40%] lg:w-[30%] rounded-lg text-white`}
                    //disabled={isSubmitting}
                  >
                    Login
                  </button>

                  <p
                    onClick={() => {
                      setShowModalForSignup(true);
                      setShowModal(false);
                    }}
                    className="text-[#232536] my-2.5 cursor-pointer hover:underline"
                  >
                    Don't have an Account?{" "}
                    <span className="text-[blue]">Signup</span>
                  </p>
                  <p
                    onClick={() => {
                      setShowModalForForgot(true);
                      setShowModal(false);
                    }}
                    className="text-[#232536] cursor-pointer hover:text-[orange]"
                  >
                    Forgot Password
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </CustomModal>
      )}
    </div>
  );
});

export default LoginPage;

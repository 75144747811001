import React, { useEffect, useState } from "react";

import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import {
  addVolunteerService,
  getVolunteerService,
} from "../../services/additionalInformationService";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import { ErrorHandler } from "../../helper/ErrorHandler";

const Volunteer = ({ info, setInfo, index }) => {
  const [loading, setLoading] = useState(false);
  const [volunteer, setVolunteer] = useState([]);

  const getVolunteer = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getVolunteerService(token, { user_id: user.id });
        if (data) setVolunteer(data);
      } else setVolunteer([]);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getVolunteer();
  }, []);

  const handleAddService = async (values) => {
    setLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await addVolunteerService(values, token);
      if (data) {
        const newArray = [...info];
        newArray[index].opened = false;
        setInfo(newArray);
        CustomToast("success", "Volunteer service added successfully");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col mt-5">
      {/* <p className="text-[#535353] w-full p-5 mt-5 bg-[#c9e2c9] text-[14px] sm:text-[18px] font-semibold  ">
        Volunteer Social Free Service
      </p> */}
      {volunteer?.map((lists, index) => (
        <div key={index} className="px-2">
          <p>Free Service {index + 1}</p>
          <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
            <p className="text-[14px]">
              Service Provided: {lists?.freeServiceProvided ?? ""}
            </p>
            <p className="text-[14px]">
              Institution: {lists?.freeServiceNameOfInstitution ?? ""}
            </p>
            <p className="text-[14px]">
              Service Year: {lists?.freeServiceYearOfService ?? ""}
            </p>
          </div>
        </div>
      ))}

      {loading ? (
        <Loader />
      ) : volunteer.length == 3 ? (
        <div>
          <ErrorMessage error={"You have already added volunteer service"} />
        </div>
      ) : (
        <FormContainer
          width="w-[100%]"
          handleSubmit={handleAddService}
          initialValues={{
            serviceProvided: "",
            nameOfInstitution: "",
            yearOfService: "",
          }}
        >
          <div className="flex flex-wrap w-full ">
            <div className="flex flex-wrap w-full ">
              <FormField
                width="w-[100%] px-5 my-1"
                label="Free Service Provided-1"
                name="serviceProvided"
                isLogin={true}
                type="text"
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="Name of institution"
                name="nameOfInstitution"
                isLogin={true}
                type="text"
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="Year of service"
                name="yearOfService"
                isLogin={true}
                type="text"
              />
            </div>
          </div>
          <FormButton isSubmitting={loading} title={"Submit"} />
        </FormContainer>
      )}
    </div>
  );
};

export default Volunteer;

import React, { useEffect, useState } from "react";
import CustomToast from "../components/CustomToast";
import ListInformation from "../components/ListInformation";
import Loader from "../components/Loader";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../helper/LocalStorage";
import {
  editProfileService,
  getUserInformationService,
} from "../services/userService";
import { ErrorHandler } from "../helper/ErrorHandler";
import moment from "moment";

function EditProfilePage() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await getUserInformationService(token);
      if (data.data) {
        setUser(data.data);
      }
    } catch (error) {
      removeLocalStorageItem();
      window.location = "/";
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };
  const handleChange = ({ currentTarget: input }) => {
    setUser({ ...user, [input.name]: input.value });
  };
  const handleUpdate = async () => {
    setUpdateLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await editProfileService(user, token);
      setUser(data.data);
      CustomToast("success", data.message);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setUpdateLoading(false);
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);
  return (
    <div className="flex flex-col py-5">
      {loading || updateLoading ? (
        <Loader />
      ) : user?.firstName ? (
        <>
          <div className="flex flex-col w-[90%] px-5 self-center shadow-lg md:w-[70%] lg:w-[50%] md:flex-row flex-wrap justify-between">
            {user?.firstName && (
              <ListInformation
                disabled
                label="Name"
                value={user?.firstName}
                onChange={handleChange}
              />
            )}
            {user?.lastName && (
              <ListInformation
                disabled
                label="Last Name"
                value={user?.lastName}
                onChange={handleChange}
              />
            )}
            {user?.grandFatherName && (
              <ListInformation
                disabled
                label="Grand Father Name"
                value={user?.grandFatherName}
                onChange={handleChange}
              />
            )}
            <ListInformation
              disabled
              label="Email"
              name="email"
              value={user?.email}
              onChange={handleChange}
            />

            <ListInformation
              label="Phone Number"
              name="phoneNumber"
              value={user?.phoneNumber}
              onChange={handleChange}
            />

            <ListInformation
              label="Country"
              name="country"
              value={user?.country}
              onChange={handleChange}
            />

            <ListInformation
              label="City"
              name="city"
              value={user?.city}
              onChange={handleChange}
            />

            {user?.birthDate && (
              <ListInformation
                disabled
                label="Birth Date"
                name="birthDate"
                value={new Date(user?.birthDate).toLocaleDateString()}
                onChange={handleChange}
              />
            )}

            <ListInformation
              label="Sate"
              name="state"
              value={user?.state}
              onChange={handleChange}
            />

            <ListInformation
              label="Year Graduated"
              name="yearGraduated"
              value={moment(user?.yearGraduated).format("YYYY-MM-DD")}
              onChange={handleChange}
              type={"date"}
            />
          </div>
          <button
            onClick={handleUpdate}
            className="bg-[orange] w-max md:w-[20%] rounded-md text-center self-center my-10 text-white p-1"
          >
            Update Profile
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default EditProfilePage;

import React, { forwardRef, useEffect, useRef } from "react";
import Loader from "./Loader";

const CustomModal = forwardRef(({ children, title, loading, onClose }, ref) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutsideModal = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [onClose]);

  return (
    <div ref={ref}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          ref={modalRef}
          className="relative my-6  mx-auto w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%]"
        >
          <div className="rounded-lg shadow-lg relative flex flex-col items-center w-full bg-[white]  outline-none focus:outline-none">
            {loading && <Loader />}

            <div className="flex items-start justify-between rounded-t">
              <h3 className="text-[14px]  my-2 sm:text-[16px] md:text-[18px] lg:text-[20px] text-left text-[#232536]">
                {title}
              </h3>
            </div>
            {children}
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-10 bg-black"></div>
    </div>
  );
});

export default CustomModal;

import React, { useState } from "react";
import * as Yup from "yup";
import CustomModal from "../components/CustomModal";
import FormContainer from "../components/CustomForm/FormContainer";
import FormField from "../components/CustomForm/FormField";
import FormButton from "../components/CustomForm/FormButton";
import CustomToast from "../components/CustomToast";
import { getLocalStorageItem } from "../helper/LocalStorage";
import { sendMessageService } from "../services/userService";
import { ErrorHandler } from "../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  message: Yup.string().required().label("Message"),
});
const Message = ({ msgModal, setMsgModal }) => {
  const [modalLoading, setModalLoading] = useState(false);

  const handleSubmit = async (value) => {
    setModalLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await sendMessageService(value, token);
      if (data.data) {
        CustomToast("success", data.data);
        setMsgModal(false);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setModalLoading(false);
  };
  return (
    <>
      {msgModal && (
        <CustomModal
          loading={modalLoading}
          onClose={() => setMsgModal(false)}
          title={"Message Us"}
        >
          <FormContainer
            width={"w-full"}
            handleSubmit={handleSubmit}
            initialValues={{ message: "", email: "" }}
            validationSchema={validationSchema}
          >
            <FormField
              label="Email"
              name="email"
              width={"w-[90%] md:w-[90%]  md:px-5 my-1"}
              isLogin={true}
            ></FormField>
            <FormField
              label="Your message"
              name="message"
              as="textarea"
              cols={"10"}
              rows={"10"}
              width={"w-[90%] md:w-[90%]  md:px-5 my-1"}
              isLogin={true}
            ></FormField>

            <FormButton title={"Send"} onClick={handleSubmit} />
          </FormContainer>
        </CustomModal>
      )}
    </>
  );
};

export default Message;

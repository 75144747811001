import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import App from "../App";

import Menu from "../assets/menu.png";
import Logo from "../assets/Logo-1.png";
import AuthContext from "../context/AuthContext";
import { removeLocalStorageItem } from "../helper/LocalStorage";
import Message from "../pages/Message";

const Header = forwardRef(({ onBackClick }, ref) => {
  const navigate = useNavigate();

  const navBar = [
    { name: "Company", path: "/company", ref: ref !== null ? ref[3] : null },
    { name: "About Us", path: "/about", ref: ref !== null ? ref[1] : null },
    { name: "Procedure", path: "/join", ref: ref !== null ? ref[2] : null },
  ];
  const [winHeight, setWinHeight] = useState(window.scrollY);
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const authContext = useContext(AuthContext);
  const [msgModal, setMsgModal] = useState(false);
  const { user, setUser } = authContext;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setWinHeight(window.scrollY);
    });
    window.addEventListener("resize", () => {
      setWinWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setWinHeight(0);
      });
      window.removeEventListener("resize", () => {
        setWinWidth(0);
      });
    };
  });

  useEffect(() => {
    winWidth < 768 ? setShowMenuBar(true) : setShowMenuBar(false);
  }, [winWidth]);

  return (
    <>
      <div
        className={`w-full p-5 lg:p-10 flex items-center drop-shadow-lg bg-[#232536] 
      ${
        winWidth > 768
          ? "flex-row justify-center items-center  h-[60px]"
          : winWidth < 768 && ref != null && ref.length
          ? "flex-col"
          : "flex-row justify-between"
      }
      ${winHeight > 70 ? "sticky z-[1] top-0 " : ""}
      `}
      >
        <div className="flex w-[100%] md:w-[25%] ">
          <Link
            to="/"
            className="text-white flex items-center hover:cursor-pointer "
            onClick={() => {
              setShowMenuBar(!showMenuBar);
              onBackClick(ref[0]);
            }}
          >
            <img
              src={Logo}
              onClick={() => setShowMenuBar(!showMenuBar)}
              className="w-[50px] md:w-[60px] lg:w-[70px]"
            ></img>
            <p className="text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px]">
              KeyUG
            </p>
          </Link>
        </div>
        {ref != null && ref.length ? (
          <div
            className={`flex ${
              showMenuBar && winWidth < 768 ? "hidden" : "flex-col w-full "
            } 
        `}
          >
            <div
              className={`flex hover:cursor-pointer ${
                winWidth < 768
                  ? "flex-col justify-center items-center "
                  : "flex-row justify-end"
              }`}
            >
              {navBar.map((nav, index) => (
                <p
                  key={index}
                  onClick={() => {
                    onBackClick(nav.ref);
                    setShowMenuBar(!showMenuBar);
                  }}
                  className={`flex justify-center mx-2 items-center text-[14px] lg:text-[16px] text-white hover:text-[#f5a91c]  ${
                    winWidth < 768
                      ? " my-2 w-[100%] p-2 bg-[#2e3144] rounded-md"
                      : ""
                  }`}
                >
                  {nav.name}
                </p>
              ))}
              {user?.firstName && (
                <p
                  className={`flex justify-center mx-2 items-center text-[14px] lg:text-[16px] text-white hover:text-[#f5a91c]  ${
                    winWidth < 768
                      ? " my-2 w-[100%] p-2 bg-[#2e3144] rounded-md"
                      : ""
                  }`}
                  onClick={() => setMsgModal(true)}
                >
                  Message Us
                </p>
              )}
              {/* {user?.firstName && (
                <NavLink
                  to={"/events"}
                  className={`flex justify-center items-center   ${
                    winWidth < 768
                      ? " my-2 w-[100%] bg-[#2e3144] p-2 rounded-md"
                      : ""
                  }`}
                >
                  <p className="text-white hover:text-[#f5a91c] text-[14px] md:text-[16px]">
                    Events
                  </p>
                </NavLink>
              )} */}

              {user?.firstName ? (
                <NavLink to={"/myProfile"} className="p-2">
                  <p className="text-[#FFA500] text-[14px] md:text-[16px]">
                    {user?.firstName
                      ? `Welcome  ${user?.firstName}`
                      : "My Profile"}
                  </p>
                </NavLink>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-end">
            <p
              onClick={() => {
                navigate("/");
                removeLocalStorageItem();
                setUser(null);
              }}
              className="px-[10px] w-[90%] max-w-[100px] text-[14px] sm:text-[16px] md:w-[20%]  text-center cursor-pointer bg-slate-200 rounded-md"
            >
              Log out
            </p>
          </div>
        )}
      </div>

      <Message msgModal={msgModal} setMsgModal={setMsgModal} />
    </>
  );
});
export default Header;

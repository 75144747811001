import React, { useEffect, useState } from "react";
import CustomDialog from "../../components/Dialog";
import * as Yup from "yup";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormField from "../../components/CustomForm/FormField";
import FormButton from "../../components/CustomForm/FormButton";
import { Button } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import {
  addKeyToUserService,
  getLastKey,
} from "../../services/userService";
import CustomToast from "../../components/CustomToast";
import moment from "moment";
import { ErrorHandler } from "../../helper/ErrorHandler";
import FormContainer from "../../components/CustomForm/FormContainer";
import Loader from "../../components/Loader";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Full Name"),
  key: Yup.number()
    .test("len", "Key must be 10 digits", (val) => val?.toString().length == 10)
    .required()
    .label("Key"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  date: Yup.date().required().label("Date"),
});

const AddEditAssignKeyToUser = ({ data,fetchData }) => {
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [latestKey, setLatestKey] = useState("");
  
  useEffect(() => {
    getKey();
  }, []);

  const getKey = async () => {
    setLoading(true);
    try {
      const { data } = await getLastKey();
      if (data) setLatestKey(data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    try {
      setAddLoading(true);
      const { data } = await addKeyToUserService(values);
      if (data) {
        CustomToast("success", "Referral added");
        getKey();
        setOpen(false);
        fetchData();
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setAddLoading(false);
  };

  return (
    <>
      <Button
        startIcon={data ? <Edit /> : <Add />}
        onClick={() => setOpen(true)}
        sx={{ marginY: 0.5 }}
        color={data ? "success" : "primary"}
        variant="outlined"
      >
        {data ? "Edit" : "Add"}
      </Button>

      <CustomDialog open={open} setOpen={setOpen} title={data ? "Edit" : "Add"}>
        {loading ? (
          <Loader />
        ) : (
          <div className="flex w-[300px] md:w-[500px] justify-center">
            <FormContainer
              initialValues={
                data
                  ? {
                      ...data,
                      date: moment(data?.date),
                    }
                  : { key: latestKey, date: moment() }
              }
              validationSchema={validationSchema}
              handleSubmit={handleSubmit}
              width="w-full"
            >
              <div className="flex flex-col items-center w-full ">
                <FormField
                  label="Full Name"
                  name="name"
                  type="text"
                  width={"w-full"}
                />
                <FormField
                  disabled={true}
                  label="Key"
                  width={"w-full"}
                  name="key"
                  type="number"
                />
                <FormField
                  label="Phone Number"
                  width={"w-full"}
                  name="phoneNumber"
                  type="number"
                />
                <FormDatePicker
                  label={"Date of invitation"}
                  name={"date"}
                  width={"w-full"}
                ></FormDatePicker>
                <div className="flex w-full justify-end">
                  <FormButton
                    loading={addLoading}
                    title={data ? "Edit" : "Add"}
                  ></FormButton>
                </div>
              </div>
            </FormContainer>
          </div>
        )}
      </CustomDialog>
    </>
  );
};

export default AddEditAssignKeyToUser;

import React, { useEffect, useState } from "react";
import FormButton from "../components/CustomForm/FormButton";
import FormContainer from "../components/CustomForm/FormContainer";
import CustomToast from "../components/CustomToast";
import FormField from "../components/CustomForm/FormField";
import Loader from "../components/Loader";
import { getLocalStorageItem } from "../helper/LocalStorage";
import { addFieldService, getAllFieldService } from "../services/fieldService";
import {
  addAdditionalFieldForUserService,
  getUserInformationService,
} from "../services/userService";
import CustomModal from "../components/CustomModal";
import { ErrorHandler } from "../helper/ErrorHandler";

function AddFieldPage() {
  const [user, setUser] = useState({});
  const [field, setField] = useState([]);
  const [newField, setNewField] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const token = getLocalStorageItem();

  const getUser = async () => {
    setLoading(true);
    try {
      const { data } = await getUserInformationService(token);
      if (data.data) {
        setUser(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const getField = async () => {
    setLoading(true);
    try {
      const { data } = await getAllFieldService(token);
      if (data) setField(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };
  useEffect(() => {
    getField();
    getUser();
    return () => {
      setUser({});
      setField([]);
    };
  }, []);

  const handleAddField = async () => {
    setLoading(true);
    if (!newField) CustomToast("error", "Please select field");
    else {
      try {
        const { data } = await addAdditionalFieldForUserService(
          newField,
          token
        );
        if (data.data) {
          CustomToast("success", data.data);
          getUser();
        }
      } catch (error) {
        ErrorHandler(error).map((e) => CustomToast("Err", e.message));
      }
    }
    setLoading(false);
  };

  const handleAdditionalField = async (values) => {
    try {
      setFieldLoading(true);
      const { data } = await addFieldService(values, token);
      if (data.data) {
        CustomToast("success", data.data);
        setModal(false);
      }
    } catch (error) {
      CustomToast(
        "error",
        error?.response?.data.message || error?.response?.message
      );
    }

    setFieldLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="flex flex-col justify-center items-center p-[20px]">
        <p className="bg-[#ffde8b] text-[14px] md:text-[16px] max-w-[600px] text-center my-5 md:mb-10 p-5 rounded-lg text-[#666262]">
          If you don't find the field you are looking for please add your field
          through{" "}
          <span
            onClick={() => setModal(true)}
            className="underline text-blue-400 cursor-pointer"
          >
            this{" "}
          </span>
          and wait until the admin approves the field
        </p>
        {user?.fields?.length ? (
          <div className="w-full md:w-[40%]">
            <p className="text-left text-[14px] md:text-[16px] text-slate-500">
              Current Field
            </p>
            {user?.fields?.map((fields, index) => (
              <p
                key={index}
                className="w-full outline-[#99d5e9] rounded-md text-[14px] md:text-[16px] p-[5px] md:p-2 bg-[#EFF0F2]"
              >
                {fields?.name ?? ""}
              </p>
            ))}
          </div>
        ) : null}

        <div className="w-full md:w-[40%]  my-2">
          <label
            htmlFor="field"
            className="block mb-2 text-[#4D5959] text-[14px] md:text-[16ppx]"
          >
            Add field
          </label>
          <select
            onClick={(e) => {
              setNewField(e.currentTarget.value);
            }}
            id="field"
            className="w-full outline-[#99d5e9] rounded-md text-[14px] md:text-[16px] p-[5px] md:p-2 bg-[#EFF0F2]"
          >
            <option value="">Select Field</option>
            {field?.map((fields, index) => (
              <option key={index} value={fields.id} className="bg-slate-200">
                {fields.name}
              </option>
            ))}
          </select>
        </div>
        <p
          onClick={handleAddField}
          className="bg-[orange] w-max md:w-[20%] cursor-pointer rounded-md text-center self-center my-10 text-white p-1 text-[14px] md:text-[16px]"
        >
          Add Field
        </p>
      </div>
      {modal && (
        <CustomModal onClose={() => setModal(false)} loading={fieldLoading}>
          <FormContainer
            handleSubmit={handleAdditionalField}
            initialValues={{ name: "" }}
            width={"w-[100%]"}
          >
            <FormField
              label={"Field"}
              name={"name"}
              width={"w-[90%]"}
              type="text"
            />

            <FormButton title={"Add"} />
          </FormContainer>
        </CustomModal>
      )}
    </div>
  );
}

export default AddFieldPage;

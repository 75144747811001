import moment from "moment";
import React from "react";

const ShareAmount = ({ items, share }) => {
  return (
    <div className="w-[100%] relative h-max bg-[#d7f5d7] rounded-lg p-5 my-1">
      <div className="absolute w-[70%] sm:w-[70%] md:w-[90%] lg:w-[70%] top-0 right-0 ">
        <div className="relative flex justify-evenly w-full md:px-5 rounded-tr-lg bg-[#183d0f]">
          <p className="text-[14px]  text-white">Total paid </p>
          <p className="text-[14px]  text-white">{items.totalPaid} ETB</p>
        </div>
      </div>
      <div className="mt-2.5">
        {" "}
        <div className="w-full items-center justify-between flex">
          <p className="text-[14px] ">Total share amount</p>
          <div className="divider"></div>
          <p className="text-[14px] ">{share?.amount} ETB</p>
        </div>
        <div className="w-full items-center justify-between flex">
          <p className="text-[14px] ">Paid amount</p>
          <p className="text-[14px] ">{items?.payment?.paymentAmount} ETB</p>
        </div>
        <div className="w-full items-center justify-between flex">
          <p className="text-[14px] ">Remaining </p>
          <p className="text-[14px] ">{items.remainingAmount} ETB</p>
        </div>
        <div className="w-full items-center justify-between flex">
          <p className="text-[14px] ">Paid on </p>
          <p className="text-[14px] ">
            {moment(items?.payment?.paymentDate).format("YYYY-MM-DD")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareAmount;

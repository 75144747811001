import React from "react";

import ReactPaginate from "react-paginate";

function Paginations({ count, onPageChange }) {
  return (
    <ReactPaginate
      activeClassName="bg-slate-800 px-3 py-1"
      disabledClassName="cursor-not-allowed "
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={count}
      nextLabel="-->"
      previousLabel="<--"
      renderOnZeroPageCount={null}
      className="flex justify-between px-3  w-[200px] items-center rounded-sm bg-slate-500 text-sm font-medium text-white my-2"
    />
  );
}

export default Paginations;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormImage from "../../components/CustomForm/FormImage";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addCurrentJobService,
  getCurrentJobService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";

const CurrentJob = ({ info, setInfo, index }) => {
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [currentJob, setCurrentJob] = useState({});
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    currentjobNameOfEmployer: Yup.string().required().label("Name of employer"),
    currentjobPosition: Yup.string().required().label("Job Position"),
    currentjobSalary: Yup.string().required().label("Net monthly salary"),
    currentjobCountry: Yup.string().required().label("Country"),
    currentjobState: Yup.string().required().label("State"),
    currentjobCity: Yup.string().required().label("City"),
    currentjobReferenceFullName: Yup.string()
      .required()
      .label("Reference Full Name"),
    currentjobReferencePhoneNo: Yup.string()
      .required()
      .label("Reference Phone Number"),
    currentjobReferenceEmail: Yup.string()
      .email({ tlds: { allow: ["com", "net", "org", "et"] } })
      .required()
      .label("Reference Email"),
    currentjobAgreementPicture: Yup.mixed()
      .required()
      .test(
        "FILE_TYPE",
        "Invalid file type, Only image is allowed",
        (value) =>
          !value ||
          (value &&
            [
              "image/png",
              "image/jpg",
              "image/jpeg",
              "application/pdf",
            ].includes(value.type))
      )
      .test(
        "FILE_SIZE",
        "File size is too large, please upload file less than 2 Mega Bytes",
        (value) => !value || (value && value.size <= 2000000)
      )
      .label("Agreement document"),
  });

  const getCurrentJob = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getCurrentJobService(token, {
          user_id: user.id,
        });
        if (data?.length) setCurrentJob(data[0]);
      } else setCurrentJob({});
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getCurrentJob();
  }, []);

  const handleAddCurrentJob = async (values) => {
    setPostLoading(true);
    const formData = new FormData();
    try {
      const token = await getLocalStorageItem();
      Object.keys(values).forEach((item) => {
        if (item == "currentjobAgreementPicture") {
          formData.append("picture", values[item]);
        } else {
          formData.append(item, values[item]);
        }
      });
      const { data } = await addCurrentJobService(
        formData,
        token,
        (progress) => {
          setProgressVisible(true);
          setProgress(Math.ceil(progress * 100));
          setProgressVisible(false);
        }
      );
      if (data) {
        const newArray = [...info];
        newArray[index].opened = !newArray[index].opened;
        setInfo(newArray);
        CustomToast("success", "Current Job Added Successfully");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setPostLoading(false);
  };

  return (
    <div className="flex flex-col">
      {/* <p className="text-[#535353] w-full p-5 mt-5 bg-[#c9c9e2] text-[14px] sm:text-[18px] font-semibold  ">
        Current Job
      </p>
          
      */}

      {loading ? (
        <Loader />
      ) : currentJob?.currentjobNameOfEmployer ? (
        <div>
          <div className="px-2">
            <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
              <p className="text-[14px] md:text-[16px]">
                Name: {currentJob.currentjobNameOfEmployer}
              </p>
              <p className="text-[14px] md:text-[16px]">
                Position: {currentJob.currentjobPosition}
              </p>
              <p className="text-[14px] md:text-[16px]">
                Salary: {currentJob.currentjobSalary}
              </p>
            </div>
          </div>
          <ErrorMessage error={"You have already added current job"} />
        </div>
      ) : (
        <div className="flex flex-col justify-center my-2 items-center">
          {postLoading && <Loader />}
          <FormContainer
            width="w-[100%]"
            handleSubmit={handleAddCurrentJob}
            validationSchema={validationSchema}
            initialValues={{
              currentjobNameOfEmployer: "",
              currentjobPosition: "",
              currentjobSalary: "",
              currentjobCountry: "",
              currentjobState: "",
              currentjobCity: "",
              currentjobReferenceFullName: "",
              currentjobReferencePhoneNo: "",
              currentjobReferenceEmail: "",
              currentjobAgreementPicture: "",
            }}
          >
            <div className="flex flex-wrap w-full ">
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Name of Employer"
                name="currentjobNameOfEmployer"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Position"
                name="currentjobPosition"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Net Monthly Salary Birr"
                name="currentjobSalary"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Country"
                name="currentjobCountry"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="State"
                name="currentjobState"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="City"
                name="currentjobCity"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Reference Full Name"
                name="currentjobReferenceFullName"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Reference Phone Number"
                name="currentjobReferencePhoneNo"
                isLogin={false}
                type="text"
              />
              <FormField
                width="w-[100%] md:w-[50%] px-5 my-1"
                label="Reference Email"
                name="currentjobReferenceEmail"
                isLogin={false}
                type="text"
              />
              <FormImage
                progress={progress}
                progressVisible={progressVisible}
                label={"Upload Agreement"}
                name="currentjobAgreementPicture"
                width={"w-[100%] md:w-[50%] px-5 my-1"}
              />
            </div>
            <FormButton title={"Submit"} />
          </FormContainer>
        </div>
      )}
    </div>
  );
};

export default CurrentJob;

// import * as React from "react";
// import { useFormikContext, ErrorMessage } from "formik";
// import ReactDatePicker from "react-datepicker";

// export default function FormDatePicker({ label, name, width }) {
//   const { values, setFieldValue } = useFormikContext();
// console.log("values",values)
//   return (
//     <div className={`${width}`}>
//       <p className="text-[#4D5959] text-[14px] text-left">{label}</p>
//       <ReactDatePicker
//         placeholderText={new Date().toLocaleDateString()}
//         autoComplete="off"
//         className="w-full outline-[#99d5e9] rounded-md text-[14px] md:text-[16px] p-[4px] md:p-2 bg-[#EFF0F2]"
//         selected={values[name] ? values[name].toDate() : undefined}
//         onChange={(newValue) => {
//           setFieldValue(name, newValue);
//         }}
//         name={name}
//         dateFormat="dd/MM/yyyy"
//         showYearDropdown
//         dropdownMode="select"
//         yearDropdownItemNumber={20}
//         minDate={new Date(1950, 11, 31)}
//         maxDate={new Date(new Date().getFullYear(), 11, 31)}
//         scrollableYearDropdown
//       />
//       <ErrorMessage
//         className="text-[#e95f5f] text-left text-[14px]"
//         name={name}
//         component="p"
//       />
//     </div>
//   );
// }


import * as React from "react";
import { useFormikContext, ErrorMessage } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

export default function FormDatePicker({ label, name, width }) {
  const { values,setFieldValue } = useFormikContext();

  return (
    <div className={`${width} my-2.5`}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          name={name}
          value={values[name]}
          className="w-full md:px-5 text-[14px] p-[2px]  hover:outline-none outline-[#99d5e9] rounded-md my-2 bg-[#EFF0F2]"
          label={label}
          onChange={(newValue) => {
            setFieldValue(name, newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <ErrorMessage
          className="text-[#e95f5f] text-left text-[14px]"
          name={name}
          component="p"
        />
      </LocalizationProvider>
    </div>
  );
}

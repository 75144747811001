import moment from "moment";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";


import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import { getLocalStorageItem } from "../../helper/LocalStorage";
import {
  getKeyAssignedToService,
  } from "../../services/userService";
import { ErrorHandler } from "../../helper/ErrorHandler";
import CustomPopover from "../../components/Popover";
import AddEditAssignKeyToUser from "./AddEditAssignKeyToUser";
import { Button } from "@mui/material";



const AssignKeyToUser = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState([]);
  const token = getLocalStorageItem();

  useEffect(() => {
    getReferral();
  }, []);

  const getReferral = async () => {
    setLoading(true);
    try {
      const { data } = await getKeyAssignedToService(token);
      if (data.data) {
        let newData = data.data;
        newData.map((items) => {
          items.date = moment(items.data).format("YYYY-MM-DD");
          items.registered = items.registered ? "Registered" : "Not registered";
        });
        setUser(newData);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Full Name",
      width: 150,
    },
    { field: "key", headerName: "Key", width: 200 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },

    {
      field: "date",
      headerName: "Date Invited",
      width: 200,
    },
    {
      field: "registered",
      headerName: "Registered",
      width: 150,
    },

    {
      headerName: "Action",
      width: 150,
      field: "action",
      renderCell: (params) => (
        <>
          <CustomPopover>
            {params?.row.registered === "Registered" ? (
              <Button variant="text" color="warning">This user is registered</Button>
            ) : (
              <AddEditAssignKeyToUser
                data={params?.row}
                fetchData={getReferral}
              />
            )}
          </CustomPopover>
        </>
      ),
    },
  ];

  return (
    <div className="flex flex-col p-2 md:p-10">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <p className="text-left sm:text-[16px] md:text-[18px] font-semibold text-slate-500">
              Assigned Keys
            </p>
            <AddEditAssignKeyToUser fetchData={getReferral} />
          </div>

          {users?.length ? (
            <>
              <DataGrid
                getRowId={(row) => row.referalId}
                loading={loading}
                rows={users}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AssignKeyToUser;

import React, { useEffect, useState } from "react";
import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addEmergencyService,
  getEmergencyService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";

const EmergencyContact = ({ info, setInfo, index }) => {
  const [loading, setLoading] = useState(false);
  const [emergency, setEmergency] = useState([]);
  const initialValue = { fullName: "", mobile: "", email: "" };

  const getEmergency = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getEmergencyService(token, {
          user_id: user?.id,
        });
        if (data) {
          setEmergency(data);
        }
      } else setEmergency([]);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getEmergency();
  }, []);

  const handleAddService = async (values) => {
    setLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await addEmergencyService(values, token);
      if (data) {
        const newArray = [...info];
        newArray[index].opened = false;
        setInfo(newArray);
        CustomToast("success", "Emergency Contact added successfully");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-center ">
      {emergency?.map((lists, index) => (
        <div key={index} className="px-2">
          <p>Emergency Contact {index + 1}</p>
          <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
            <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
              <p className="text-[14px]">Full Name: {lists?.fullName ?? ""}</p>
              <p className="text-[14px]">Mobile: {lists?.mobile ?? ""} </p>
              <p className="text-[14px]">Email: {lists?.email ?? ""}</p>
            </div>
          </div>
        </div>
      ))}

      {loading ? (
        <Loader />
      ) : emergency?.length == 2 ? (
        <ErrorMessage error={"You can only add two emergency contact"} />
      ) : (
        <FormContainer
          initialValues={initialValue}
          width="w-[100%]"
          handleSubmit={handleAddService}
        >
          <div className="flex flex-wrap w-full ">
            <FormField
              width="w-[100%] px-5 my-1"
              label="Full Name"
              name={"fullName"}
              type="text"
            />
            <FormField
              width="w-[100%] px-5 my-1"
              label="Mobile"
              name={"mobile"}
              type="text"
            />
            <FormField
              width="w-[100%] px-5 my-1"
              label="Email"
              name={"email"}
              type="text"
            />
          </div>
          <FormButton isSubmitting={loading} title={"Submit"} />
        </FormContainer>
      )}
    </div>
  );
};

export default EmergencyContact;

import React from "react";

function Loader({ width, height }) {
  return (
    <div className="flex justify-center items-center my-2">
      <div className="flex items-center justify-center ">
        <div
          style={{
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
          className="border-b-[2px] border-t-[2px]  border-slate-500 rounded-full animate-spin"
        ></div>
      </div>
    </div>
  );
}

export default Loader;

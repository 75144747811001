import axios from "axios";
import { URL } from "../config/config";
import { header } from "../helper/APIToken";
import { useData } from "../hooks/useData";

export const registerG1Service = (value) => {
  return axios.post(`${URL}registerG1`, value);
};

export const registerService = (value, token, role, onUploadProgress) => {
  return axios.post(
    `${URL}${role == 2 || role == 3 ? "user/" : ""}registerG${role}`,
    value,
    {
      headers: {
        "x-auth-token": token,
      },
      onUploadProgress: (progress) =>
        onUploadProgress(progress.loaded / progress.total),
    }
  );
};
export const registerPremiumUserService = (value, token, onUploadProgress) => {
  return axios.post(`${URL}registerPremiumUser`, value, {
    headers: {
      "x-auth-token": token,
    },
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};
export const sendMessageService = (value, token) => {
  return axios.post(`${URL}message`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getUserInformationService = (token) => {
  return axios.get(`${URL}getUser`, header());
};
export const getBannerAndTotalUserService = () => {
  return axios.get(`${URL}getBannerAndTotalUser`);
};
export const addAdditionalFieldForUserService = (field_id, token) => {
  return axios.post(
    `${URL}addUserField`,
    { field_id },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};
export const changePasswordService = (token, values) => {
  return axios.post(`${URL}changePassword`, values, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const setWithDrawalPercentageService = async (token, percentage) => {
  return axios.put(`${URL}setWithDrawalPercentage/${percentage}`, null, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const loginService = (value) => {
  return axios.post(`${URL}login`, value);
};

export const editProfileService = (value, token) => {
  return axios.put(`${URL}editMyProfile`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addKeyToUserService = (value, token) => {
  return axios.post(`${URL}v1/referral`, value, header());
};
export const getKeyAssignedToService = () => {
  return axios.get(`${URL}referral`, header());
};
export const getLastKey = () => {
  return axios.get(`${URL}v1/referral/lastKey`, header());
};
export const submitEmailService = (email) => {
  return axios.post(`${URL}forgotPassword`, email);
};

export const resetPasswordService = (token, password) => {
  return axios.post(`${URL}resetPassword`, password, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const addUserByAgentService = (value, onUploadProgress) => {
  return axios.post(`${URL}addUser`, value, {
    ...header(),
    onUploadProgress: (progress) =>
      onUploadProgress(progress.loaded / progress.total),
  });
};


const useUser=()=>useData("/getUser",header())
export default useUser
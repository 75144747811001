import axios from "axios";
import { getLocalStorageItem, setLocalStorageItem } from "./LocalStorage";
import { URL } from "../config/config";
import jwtDecode from "jwt-decode";
import CustomToast from "../components/CustomToast";

export async function refreshToken() {
  const token = getLocalStorageItem();
  try {
    const { data } = await axios.get(`${URL}refreshToken`, {
      headers: {
        "x-auth-token": token,
      },
    });
    setLocalStorageItem(data.token);
    const user = jwtDecode(data.token);
    const timeUntilExpiration = user?.exp * 1000 - Date.now();
    const timeUntilRefresh = timeUntilExpiration / 2;
    setTimeout(refreshToken, timeUntilRefresh);
    return data.token;
  } catch (error) {
    CustomToast("error", error);
  }
}

import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import AuthContext from "../../context/AuthContext";
import { removeLocalStorageItem } from "../../helper/LocalStorage";
import Logo from "../../assets/Logo-1.png";
import useUser from "../../services/userService";
import Loader from "../Loader";

const drawerWidth = 240;

function SideMenu(props) {
  const { data, error, loading } = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, setUser } = React.useContext(AuthContext);
  



  if (error) return console.log("error for side", error);
  if (loading) return <Loader />;

  const roleId = data?.role?.id != null ? parseInt(data.role.id) + 1 : null;
  const findRole = () => {
    return data?.isApproved
      ? parseInt(data?.role_id) < 7
        ? "signupForG" + (parseInt(data?.role_id) + 1)
        : ""
      : "underReview";
  };
  const navBar = [
    { name: "Home Page", path: "/" },
    { name: "Events", path: "events" },
    { name: "Upgrade my role", path: loading ? null : findRole() },
    { name: "Change Password", path: "changePassword" },
    { name: "Add Field", path: "addField" },
    { name: "Profile", path: "profilePage" },
    { name: "My Status", path: "myStatus" },
    { name: "Add User", path: "addUser" },
  ];

// const navBarState = React.useMemo(() => {
//   return { roleId: roleId};
// }, [roleId]);

  const filteredNavBar = data?.role_id < 2 ? navBar.slice(0, 4) : navBar;

  const { window } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    removeLocalStorageItem();
    setUser({});
  };
  const drawer = (
    <div className="bg-[#ffffff] px-1.5 mt-[55px]">
      <List className="bg-[#ffffff] min-h-[calc(100vh-56px)]">
        {filteredNavBar.map((text, index) => (
          <ListItem key={index} disablePadding>
            <NavLink
              to={`${text.path}`}
              className={({ isActive }) =>
                "nav-link items-start mt-1 w-[100%] rounded-md p-1" +
                (isActive ? " bg-[#5562ac] text-gray-100" : "text-gray-700")
              }
              state={{ roleId: parseInt(data?.role_id) + 1 }}
            >
              <ListItemButton>
                <ListItemText primary={text.name} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
  

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div sx={{ height: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="w-full"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {/* <Toolbar className="bg-[#232536]  flex justify-between"> */}
        <Toolbar className="bg-[#ffffff]  flex justify-between">
          <div className="flex items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" },color:"black" }}
            >
              <MenuIcon />
            </IconButton>

            <img src={Logo} className="w-[40px] md:w-[50px] lg:w-[60px]"></img>
          </div>
          <Button onClick={handleLogout} variant="contained" color="error">
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box
            component="div"
            sx={{
              width: { sm: drawerWidth },
              flexShrink: { sm: 0 },
            }}
            aria-label="Admin Menu"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { sm: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              px: 2,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              height: "100vh",
              backgroundColor:"white"
              }}
          >
            <Toolbar />

            {props.children}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default SideMenu;

import React, { useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";

import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import {
registerService,
} from "../../services/userService";
import { getLocalStorageItem } from "../../helper/LocalStorage";
import { getAllBankService } from "../../services/bankService";
import { getRoleInformationService } from "../../services/roleService";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  MyShareService,
  getShareForRoleService,
} from "../../services/shareService";
import ErrorMessage from "../../components/ErrorMessage";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormField from "../../components/CustomForm/FormField";
import FormButton from "../../components/CustomForm/FormButton";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ShareAmount from "../../components/ShareAmount";

const validationSchema = Yup.object().shape({
  paymentAmount: Yup.number().required().label("Payment Amount"),
  bank: Yup.string().required().label("Bank"),
  paymentDate: Yup.date().required().label("Payment Date"),
  paymentReferenceNo: Yup.string().required().label("Payment Reference Number"),
});
const RegisterForShares = () => {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0.5);

  const { state } = useLocation();
  const { roleId } = state;
  const navigate = useNavigate();
  const [bank, setBank] = useState([]);
  const [myShare, setMyShare] = useState([]);
  const [roles, setRoles] = useState({});
  const [share, setShare] = useState({});
  const token = getLocalStorageItem();

  const fetchAllInformation = async () => {
    setLoading(true);
    try {
      const { data: bankResponse } = await getAllBankService();
      const { data: roleResponse } = await getRoleInformationService(
        roleId,
        );
        
        const { data: shareRoleResponse } = await getShareForRoleService(
          roleId,
          token
        );
      const { data: shareResponse } = await MyShareService(
        shareRoleResponse.data.id,
        token
      );
      shareRoleResponse.data.role == "G5"
        ? setPercentage(0.5)
        : shareRoleResponse.data.role == "G6"
        ? setPercentage(0.3)
        : setPercentage(0.2);

      setShare(shareRoleResponse.data);
      setBank(bankResponse.data);
      setRoles(roleResponse.data);
      setMyShare(shareResponse);

      setLoading(false);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInformation();
  }, []);

  const handleRegister = async (values) => {
    setLoading(true);
    const token = getLocalStorageItem();
    values.shareId = share?.id;

    try {
      const { data } = await registerService(values, token, roleId, () => {});
      if (data)
        CustomToast(
          "success",
          "Request to buy share submitted, please wait till we approve you"
        );
      navigate("/", { replace: true });
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };
  let amount;
  if (share.amount)
    amount = [
      {
        id: share?.amount,
        percentage: "100%",
        name: `${share?.amount} ETB`,
      },
      {
        id: 0.75 * share?.amount,
        percentage: "75%",
        name: `${0.75 * share?.amount} ETB`,
      },
      {
        id: 0.5 * share?.amount,
        percentage: "50%",
        name: `${0.5 * share?.amount} ETB`,
      },
      roles?.name == "G7"
        ? {
            id: percentage * share?.amount,
            percentage: percentage * 100 + "%",
            name: `${percentage * share?.amount} ETB`,
          }
        : roles?.name == "G6"
        ? {
            id: percentage * share?.amount,
            percentage: percentage * 100 + "%",
            name: `${percentage * share?.amount} ETB`,
          }
        : {},
    ];

  let array;

  const totalAmount =
    myShare.length &&
    myShare?.reduce((acc, curr) => acc + curr?.payment?.paymentAmount, 0);

  const paymentList = myShare.map((payment, index) => {
    const totalPaid = myShare
      .slice(0, index + 1)
      .reduce((acc, curr) => acc + curr?.payment?.paymentAmount, 0);
    const remainingAmount = share?.amount - totalPaid;
    return {
      ...payment,
      remainingAmount: remainingAmount,
      totalPaid: totalAmount,
    };
  });

  array = amount?.filter((items) => {
    if (myShare?.length) {
      if (items?.id > totalAmount) {
        items.id = items?.id - totalAmount;
        items.name = `Pay ${items?.id} to buy ${items?.percentage}`;
        return items;
      }
    } else {
      items.name = items.name
        ? `Pay ${items?.id} to buy ${items?.percentage}`
        : "";
      return items;
    }
  });

  return (
    <div className="min-h-[calc(100vh-100px)] w-full flex flex-col items-center">
      <div className="alert bg-[#f1a05c] w-[100%] my-5">
        <p className="text-[14px] text-white">
          The 'select share' option displays the amount you need to pay
          according to the share percentage. For example if you have already
          bought 25% of the share and you want to buy more share then the share
          list will display the difference you need to add on the 25% you bought
          and the next share.
        </p>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col md:flex-row w-[100%]">
          <div className="flex flex-col md:flex-[0.4]">
            {paymentList.length ? (
              paymentList?.map((items, index) => {
                return <ShareAmount items={items} share={share} key={index} />;
              })
            ) : (
              <p className="text-center text-error">No Share bought yet</p>
            )}
          </div>

          <div className="flex flex-col flex-1 md:flex-[0.6] justify-center items-center w-full">
            {!bank.length || !roles.fee ? (
              <ErrorMessage
                error={"Couldn't fetch bank information please reload"}
              />
            ) : (
              <Formik
                initialValues={{
                  paymentAmount: 0,
                  bank: "",
                  paymentDate: moment(),
                  paymentReferenceNo: "",
                }}
                onSubmit={(values) => {
                  handleRegister(values);
                }}
                validationSchema={validationSchema}
              >
                {({ values }) => (
                  <Form className="flex flex-col flex-wrap w-[100%]  max-w-[700px] shadow-lg items-center rounded-lg">
                    {(() => {
                      const selectedBank =
                        bank[
                          bank.findIndex((banks) => banks?.name == values?.bank)
                        ];

                      return (
                        !!(values.paymentAmount && values.bank) && (
                          <div className="alert bg-info flex justify-center items-center rounded-bl-[0] rounded-br-[0]">
                            <BsFillInfoCircleFill size={20} color="white" />
                            <p className="text-center text-[whitesmoke]">
                              Pay {values.paymentAmount} ETB
                              {values?.bank &&
                                " using " +
                                  selectedBank?.name +
                                  " account number " +
                                  selectedBank?.accountNumber}
                            </p>
                          </div>
                        )
                      );
                    })()}

                    <FormDropDown
                      data={array}
                      label={"Share"}
                      name={"paymentAmount"}
                      width={"w-[90%] md:px-5 my-2"}
                    />
                    <FormDropDown
                      data={bank}
                      label={"Bank"}
                      name={"bank"}
                      width={"w-[90%] md:px-5 my-2"}
                    />

                    <FormField
                      label="Payment Reference Number"
                      name="paymentReferenceNo"
                      type="text"
                      width={"w-[90%] md:px-5 my-2"}
                    />

                    <FormDatePicker
                      label={"Payment Date"}
                      name={"paymentDate"}
                      width={"w-[90%] md:px-5 my-2"}
                    />

                    <FormButton title={"Upgrade"} loading={loading} />
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterForShares;

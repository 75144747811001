import axios from "axios";
import { URL } from "../config/config";

export const requestPaymentService = (value, token) => {
  return axios.post(`${URL}withdrawalPayment`, value, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getPaymentStatusService = (token) => {
  return axios.get(`${URL}withdrawalPayment/mine`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

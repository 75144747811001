import { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";


function CustomDialog({ children, title, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
        }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
}

export default CustomDialog;

import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import moment from "moment";

import {
  addFieldService,
  getAllFieldService,
} from "../../services/fieldService";
import { registerService } from "../../services/userService";

import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import { getLocalStorageItem } from "../../helper/LocalStorage";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import FormButton from "../../components/CustomForm/FormButton";
import CustomModal from "../../components/CustomModal";
import FormContainer from "../../components/CustomForm/FormContainer";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import { ErrorHandler } from "../../helper/ErrorHandler";
const validationSchema = Yup.object().shape({
  lastName: Yup.string().required().label("Father Name"),
  grandFatherName: Yup.string().required().label("Grand Father Name"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  university: Yup.string().required().label("University"),
  field_id: Yup.string().required().label("Field"),
  yearGraduated: Yup.string().required().label("Year graduated"),
  referal: Yup.string().required().label("This field"),
  referalCode: Yup.string().when("referal", {
    is: "agent",
    then: Yup.string().required().label("Referal code"),
    otherwise: Yup.string().nullable(),
  }),
  serialKey: Yup.number()
    .label("Key")
    .when("referal", {
      is: "agent",
      then: Yup.number()
        .test(
          "len",
          "Key must be 10 digits",
          (val) => val?.toString().length == 10
        )
        .required(),
      otherwise: Yup.number(),
    }),
});

function SignUpForG2() {
  const [field, setField] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const navigate = useNavigate();
  const token = getLocalStorageItem();

  const getField = async () => {
    setLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await getAllFieldService(token);
      if (data) setField(data.data);
    } catch (error) {
      CustomToast("error", error.response.data.message ?? error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getField();
    return () => {
      setField([]);
    };
  }, []);

  const handleRegister = async (values) => {
    setRegisterLoading(true);
    try {
      const { data } = await registerService(values, token, 2, () => {});
      if (data) {
        CustomToast(
          "success",
          "You have successfully registered for the next role"
        );
        navigate("/", { replace: true });
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setRegisterLoading(false);
  };
  const handleModal = () => {
    setModal(!modal);
  };

  const handleAddField = async (values) => {
    try {
      setFieldLoading(true);
      const { data } = await addFieldService(values, token);
      if (data.data) {
        CustomToast("success", data.data);
        setModal(false);
        setFieldLoading(false);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setFieldLoading(false);
  };
  return (
    <div className="flex flex-col items-center">
      <p className="bg-[#ffde8b] w-[100%] md:flex-row text-center my-5 rounded-lg text-[#666262] text-[14px] md:text-[16px] p-[2px]">
        If you don't find the field you are looking for please add your field
        through{" "}
        <span
          onClick={handleModal}
          className="underline text-blue-400 cursor-pointer"
        >
          this{" "}
        </span>
        and wait until the admin approves the field
      </p>

      {modal && (
        <CustomModal
          loading={loading}
          onClose={() => setModal(false)}
          title={"Add Field"}
        >
          <div className="flex justify-center w-full my-2">
            <FormContainer
              initialValues={{ name: "" }}
              handleSubmit={handleAddField}
              width="w-[90%] lg:max-w-[600px]"
            >
              <div className="flex flex-col items-center w-full ">
                <FormField
                  label="Field Name"
                  name="name"
                  type="text"
                  width={"w-full"}
                />

                <div className="flex w-full justify-center">
                  <FormButton loading={fieldLoading} title={"Add"}></FormButton>
                </div>
              </div>
            </FormContainer>
          </div>
        </CustomModal>
      )}

      <Formik
        initialValues={{
          lastName: "",
          grandFatherName: "",
          country: "",
          state: "",
          city: "",
          university: "",
          field_id: "",
          yearGraduated: moment(),
          referal: "",
          referalCode: "",
          serialKey: 0,
        }}
        onSubmit={handleRegister}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="flex flex-col md:flex-row  bg-slate-300 p-2 md:p-0 flex-wrap w-[100%] rounded-lg">
            <FormField
              width="w-[100%] md:w-[50%] md:px-5 "
              label="Father Name"
              name="lastName"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] md:px-5"
              label="Grand Father Name"
              name="grandFatherName"
              type="text"
            />

            <div className="w-[100%] md:w-[50%] md:px-5">
              <label
                htmlFor="field"
                className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
              >
                Country
              </label>
              <CountryDropdown
                classes="py-2.5 rounded-md w-full outline-[#99d5e9]"
                value={values.country}
                onChange={(val) => setFieldValue("country", val)}
              />
            </div>
            <FormField
              width="w-[100%] md:w-[50%] md:px-5"
              label="State"
              name="state"
              type="text"
            />
            <FormField
              width="w-[100%] md:w-[50%] md:px-5"
              label="City"
              name="city"
              type="text"
            />
            <FormDropDown
              data={[
                {
                  id: "postgraduate",
                  name: "Postgraduate",
                },
                {
                  id: "graduate",
                  name: "Graduate",
                },
                {
                  id: "undergraduate",
                  name: "Under Graduate",
                },
                {
                  id: "Secondary School",
                  name: "Secondary School",
                },
                {
                  id: "Primary School",
                  name: "Primary School",
                },
              ]}
              label="Highest Education Level"
              name="university"
              width={"w-[100%] md:w-[50%] md:px-5"}
            />
            <FormDropDown
              data={field}
              label="Field"
              name="field_id"
              width={"w-[100%] md:w-[50%] md:px-5"}
            />

            <FormDatePicker
              name={"yearGraduated"}
              label={" Graduation Year"}
              width={"w-[100%] md:w-[50%] md:px-5"}
            />

            <div className="w-[100%] md:w-[50%] md:px-5">
              <label
                htmlFor="field"
                className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
              >
                How do you hear about us?
              </label>
              <select
                onChange={(e) => {
                  if (e.currentTarget.value == "others") {
                    setFieldValue("referalCode", "no");
                    setFieldValue("referal", e.currentTarget.value);
                  } else {
                    setFieldValue("referal", e.currentTarget.value);
                    setFieldValue("referalCode", "");
                  }
                }}
                id="field"
                className="bg-gray-50 outline-[#99d5e9] text-gray-900 rounded-lg w-full text-[14px] md:text-[16px] p-[5px]  "
              >
                <option value="">Select</option>
                <option value={"others"} className="">
                  Other
                </option>
                <option value={"agent"} className="">
                  Agent
                </option>
              </select>
              <ErrorMessage
                className="text-[red]"
                name="referal"
                component="p"
              />
            </div>
            {values.referal == "agent" && (
              <>
                <FormField
                  width="w-[100%] md:w-[50%] md:px-5"
                  label="Referal Code for the agent"
                  name="referalCode"
                  type="text"
                />

                <FormField
                  width="w-[100%] md:w-[50%] md:px-5"
                  label="Serial Key"
                  name="serialKey"
                  type="number"
                />
              </>
            )}
            <div className="flex w-full mt-5 justify-end">
              <FormButton
                title={"Upload"}
                loading={loading || registerLoading}
                isSubmitting={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SignUpForG2;

import React from "react";
import FormDropDown from "../../../components/CustomForm/FormDropDown";
import FormField from "../../../components/CustomForm/FormField";
import FormDatePicker from "../../../components/CustomForm/FormDatePicker";
import FormImage from "../../../components/CustomForm/FormImage";

const G3 = ({ bank, progress, progressVisible }) => {
  return (
    <>
      <div className="w-full divider divider-primary">
        <p className="bg-[beige] text-black p-2 rounded-md">G3 Information</p>
      </div>

      <FormDropDown
        data={bank}
        label={"Bank"}
        name={"bank"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        type="text"
        name="paymentReferenceNo"
        label="Payment Reference Number"
      />

      <FormDatePicker
        label={"Payment Date"}
        name={"paymentDate"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
        <FormImage
          progress={progress}
          progressVisible={progressVisible}
          label="Education certificate"
          name="document"
          width={"100%"}
        />
      </div>
    </>
  );
};

export default G3;

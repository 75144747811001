import axios from "axios";
import { URL } from "../config/config";
import { useData } from "../hooks/useData";
import { header } from "../helper/APIToken";

export const getAllBankService = async () => {
  return await axios.get(`${URL}banks`, header());
};
export const getSpecificBankInfoService = async (id) => {
  return await axios.get(`${URL}bank/${id}`);
};

export const useBank = (deps) => useData(`banks`, header(),deps);

import { getLocalStorageItem } from "./LocalStorage";

const header = () => {
  return {
    headers: {
      "x-auth-token": getLocalStorageItem(),
    },
  };
};

export { header };

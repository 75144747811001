import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";

import CustomToast from "../components/CustomToast";
import { getUserInformationService } from "../services/userService";
import { getLocalStorageItem } from "./LocalStorage";

const ProtectAgentRoute = (props) => {
  const [isAgent, setIsAgent] = useState(false);

  const checkIfAgent = async () => {
    const token = getLocalStorageItem();
    try {
      const { data } = await getUserInformationService(token);
      if (data) {
        if (data?.data?.role?.name == "G4") setIsAgent(true);
        else {
          setIsAgent(false);
          // CustomToast("error", "This option is only available for agents");
        }
      }
    } catch (error) {
      CustomToast("error", "Error occurred, Please try again!");
    }
  };
  useEffect(() => {
    checkIfAgent();
  }, [isAgent]);

  return (
    <div className="flex min-h-[50vh]">
      {isAgent ? (
        props.children
      ) : (
        <div className="flex flex-1 flex-col justify-center items-center">
          <ErrorIcon color="error" sx={{ fontSize: 80 }}></ErrorIcon>
          <Typography fontSize={20} fontFamily={"cursive"}>
            This option is only available for agents
          </Typography>
        </div>
      )}
    </div>
  );
};
export default ProtectAgentRoute;

import { ErrorMessage, Field } from "formik";
import React from "react";

const FormDropDown = ({ label, width, name, data }) => {
  return  (
    <>
      <div className={`${width}`}>
        <p className="text-[#4D5959] text-left text-[14px]">{label}</p>
        <Field
          className="w-full outline-[#99d5e9] rounded-md text-[14px] p-2 bg-[#EFF0F2]"
          as="select"
          name={name}
        >
          <option value={""}>Select {label}</option>
          {data?.map((values, index) => (
            <option
              key={index}
              value={label.toLowerCase() === "bank" ? values?.name : values?.id}
            >
              {values?.name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          className="text-[#e95f5f] text-left text-[14px]"
          name={name}
          component="p"
        />
      </div>
    </>
  ) ;
};

export default FormDropDown;

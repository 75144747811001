import React, { forwardRef } from "react";
import { Link, NavLink } from "react-router-dom";
import FaceBook from "../assets/fb.png";
import Instagram from "../assets/ig.png";
import LinkedIn from "../assets/ln.png";

const Footer = forwardRef(({ onBackClick }, ref) => {
  const date = new Date();
  const socials = [
    { name: FaceBook, to: "https://facebook.com" },
    { name: Instagram, to: "https://instagram.com" },
    { name: LinkedIn, to: "https://linkedin.com" },
  ];

  const navBar = [
    { name: "Company", path: "/company", ref: ref !== null ? ref[3] : null },
    { name: "About Us", path: "/about", ref: ref !== null ? ref[1] : null },
    { name: "Procedure", path: "/join", ref: ref !== null ? ref[2] : null },
    // { name: "Contact us", path: "/contact", ref: ref !== null ? ref[0] : null },
  ];
  return (
    <div className="flex flex-col items-center min-h-[200px] bg-[#232536] pb-5">
      <div className="w-full flex flex-row justify-around my-5">
        <h2
          onClick={() => onBackClick(ref[0])}
          className="text-slate-400 text-[12px] sm:text-[14px] md:text-[16px] hover:text-slate-200"
        >
          ETAMB
        </h2>
        <div className="flex flex-row items-center">
          {navBar.map((nav, index) => (
            <p
              onClick={() => onBackClick(nav.ref)}
              key={index}
              className="text-slate-400 text-[12px] sm:text-[14px] md:text-[16px] hover:cursor-pointer hover:text-slate-200 mx-3 "
            >
              {nav.name}
            </p>
          ))}
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.2786680233068!2d38.80019935450789!3d9.021853724902886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85ca21304197%3A0xc73a158506cd6bef!2z4Yib4Yir4Ym24YqVIOGLqOGMiOGJoOGLqyDhiJvhi5XhiqjhiI0gTWFyYXRvbiBCdXNpbmVzcyBDZW50ZXI!5e0!3m2!1sen!2sus!4v1678042924497!5m2!1sen!2sus"
        className="w-full h-[400px] my-2 bg-gray-500"
        style={{
          border: "0",
          filter: "invert(90%)",
        }}
        color="red"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        allowtransparency="true"
      ></iframe>
      <div className="flex flex-row my-5 w-full items-center justify-center flex-1">
        {socials.map((social, index) => (
          <a key={index} href={social.to} target="_blank">
            <img
              src={social.name}
              className="mx-2 rounded-xl"
              style={{ width: 30, height: 30 }}
            ></img>
          </a>
        ))}
      </div>
      <p className="text-white text-center py-2 bg-[#3a3d56]  w-full">
        {date.getFullYear()}
      </p>
    </div>
  );
});

export default Footer;

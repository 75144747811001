import React, { useEffect, useState } from "react";
import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addSkillService,
  getSkillService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";

const Skill = ({ info, setInfo, index }) => {
  const [skill, setSkill] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSkill = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getSkillService(token, { user_id: user.id });
        if (data) setSkill(data);
      } else setSkill([]);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getSkill();
  }, []);

  const handleSubmitSkill = async (values) => {
    const token = await getLocalStorageItem();
    setLoading(true);
    try {
      const { data } = await addSkillService(values, token);
      if (data) {
        const newArray = [...info];
        newArray[index].opened = false;
        setInfo(newArray);
        CustomToast("success", "Skill added successfully");
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col">
      {/* <p className="text-[#535353] w-full p-5 mt-5 bg-[#e2c9d4] text-[14px] sm:text-[18px] font-semibold  ">
        Skills
      </p> */}

      {skill?.map((lists, index) => (
        <div key={index} className="px-2">
          <p>Skill {index + 1}</p>
          <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
            <p className="text-[14px]">{lists.skill}</p>
          </div>
        </div>
      ))}
      {loading ? (
        <Loader />
      ) : skill.length >= 3 ? (
        <ErrorMessage error={"You have already added skill"} />
      ) : (
        <div className="flex justify-center my-2 items-center">
          <FormContainer
            width="w-[100%]"
            handleSubmit={handleSubmitSkill}
            initialValues={{ skill: "" }}
          >
            <div className="flex flex-wrap w-full ">
              <FormField
                width="w-[90%] md:w-[100%] px-5 my-1"
                label="Skill"
                name="skill"
                isLogin={true}
                type="text"
              />
            </div>
            <FormButton title={"Submit"} />
          </FormContainer>
        </div>
      )}
    </div>
  );
};

export default Skill;

import axios from "axios";
import { URL } from "../config/config";

export const getInvitedUserInformationService = async (token) => {
  return await axios.get(`${URL}getInvitedUserInformation`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getInvitedUserListInEachRoleService = async (
  token,
  isPremiumUser,
  role_id
) => {
  return await axios.get(
    `${URL}getInvitedUserListInEachRole/${isPremiumUser}/${role_id}`,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};
export const fetchInvitedUserWithValueService = async (token) => {
  return await axios.get(`${URL}fetchInvitedUserWithValue/`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const fetchInvitedUserLengthInEachRoleService = async (token) => {
  return await axios.get(`${URL}fetchInvitedUserLengthInEachRole/`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getPaymentInformationService = async (token) => {
  return await axios.get(`${URL}getPaymentInformation`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

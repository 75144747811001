import React, { useEffect } from "react";

import {ErrorMessage, useFormikContext } from "formik";
import FormField from "../../../components/CustomForm/FormField";
import FormDatePicker from "../../../components/CustomForm/FormDatePicker";
import FormImage from "../../../components/CustomForm/FormImage";
import FormDropDown from "../../../components/CustomForm/FormDropDown";
import { CountryDropdown } from "react-country-region-selector";


const G2 = ({field,role}) => {
    const {setFieldValue,values,errors} = useFormikContext()
    
    useEffect(() => {
      setFieldValue("role", role);
    }, [role]);

    const education = [
        {
          id: "postgraduate",
          name: "Postgraduate",
        },
        {
          id: "graduate",
          name: "Graduate",
        },
        {
          id: "undergraduate",
          name: "Under Graduate",
        },
        {
          id: "Secondary School",
          name: "Secondary School",
        },
        {
          id: "Primary School",
          name: "Primary School",
        },
      ];
  return (
    <>
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="First Name"
        name="firstName"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Phone Number"
        name="phoneNumber"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Email"
        name="email"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Password"
        name="password"
        type="text"
      />

      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Father Name"
        name="lastName"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="Grand Father Name"
        name="grandFatherName"
        type="text"
      />
      <div className="w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]">
        <label
          htmlFor="field"
          className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
        >
          Country
        </label>
        <CountryDropdown
          classes="py-2.5 rounded-md bg-[#EFF0F2] w-full outline-[#99d5e9]"
          value={values.country}
          onChange={(val) => setFieldValue("country", val)}
        />
      </div>
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="State"
        name="state"
        type="text"
      />
      <FormField
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
        label="City"
        name="city"
        type="text"
      />
      <FormDropDown
        data={education}
        label="Highest Education Level"
        name="university"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />
      <FormDropDown
        data={field}
        label="Field"
        name="field_id"
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

      <FormDatePicker
        name={"yearGraduated"}
        label={"Graduation Year"}
        width={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}
      />

     
      <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
        <label
          htmlFor="field"
          className="block text-[#4D5959] text-[14px] md:text-[16px] p-[2px] "
        >
          How do you hear about us?
        </label>
        <select
          name="referal"
          onChange={(e) => {
            if (e.currentTarget.value == "others") {
              setFieldValue("referalCode", "no");
              setFieldValue("referal", e.currentTarget.value);
            } else {
              setFieldValue("referal", e.currentTarget.value);
              setFieldValue("referalCode", "");
            }
          }}
          id="field"
          className="bg-gray-50 outline-[#99d5e9] text-gray-900 rounded-lg w-full text-[14px] md:text-[16px] p-[5px]  "
        >
          <option value="">Select</option>
          <option value={"others"} className="">
            Others
          </option>
          <option value={"agent"} className="">
            Agent
          </option>
        </select>
       {errors["referal"] && <ErrorMessage className="text-[red]" name="referal" error={errors["referal"]} component="p" />}
      </div>
      {values?.referal !== "agent" && (
        <div className="w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"></div>
      )}

      {values.referal == "agent" && (
        <>
          <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
            <FormField
              width={"w-[100%] "}
              label="Referal Code for the agent"
              name="referalCode"
              type="text"
            />
          </div>
          <div className={"w-[100%] sm:w-[80%] md:w-[40%] lg:w-[30%]"}>
            <FormField
              width={"w-[100%]"}
              label="Serial Key"
              name="serialKey"
              type="number"
            />
          </div>
        </>
      )}
    </>
  );
};

export default G2;

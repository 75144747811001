import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CustomToast from "../components/CustomToast";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import { getLocalStorageItem } from "../helper/LocalStorage";
import { getInvitedUserListInEachRoleService } from "../services/agentService";
import { ErrorHandler } from "../helper/ErrorHandler";

const InvitedUser = () => {
  const [user, setUser] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const getInvitedUser = async () => {
    setLoading(true);
    const token = await getLocalStorageItem();
    try {
      const { data } = await getInvitedUserListInEachRoleService(
        token,
        state?.isPremiumUser,
        state?.role_id
      );
      if (data.data) setUser(data?.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };
  useEffect(() => {
    getInvitedUser();
  }, []);

  let filteredUser = user?.filter((users) =>
    users?.firstName?.match(new RegExp(filter, "i"))
  );

  return (
    <div className="px-10">
      {loading ? (
        <Loader />
      ) : (
        <div className="my-5">
          <p className="text-slate-600 font-semibold text-[20px]">
            Invited User
          </p>
          <div className="flex flex-row w-max rounded-md bg-slate-500 my-3 place-self-start">
            <label
              className="text-lg text-white font-semibold p-1"
              htmlFor="filter"
            >
              Filter
            </label>
            <input
              type="text"
              name="filter"
              className="border border-slate-400 m-2 focus:outline-none rounded-sm focus:border-red-500 focus:ring-1 focus:ring-red-500"
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            ></input>
          </div>
          {filteredUser?.length ? (
            <table className="table-auto justify-center border-slate-300 w-[100%]">
              <thead className="bg-slate-500 w-[100%]">
                <tr>
                  <th className="border w-[5%] text-white p-2 border-slate-600">
                    No
                  </th>
                  <th className="border w-[25%] text-white p-2 border-slate-600">
                    First Name
                  </th>
                  <th className="border w-[25%] text-white p-2 border-slate-600">
                    Last Name
                  </th>
                  <th className="border w-[15%] text-white p-2 border-slate-600">
                    Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUser.map((users, index) => (
                  <tr key={index} className="bg-slate-200 hover:bg-slate-300">
                    <td className="border border-slate-800 text-left  text-slate-800 p-2 text-lg ">
                      {index + 1}
                    </td>
                    <td className="border border-slate-800 text-left  text-slate-800 p-2 text-lg ">
                      {users?.firstName}
                    </td>
                    <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                      {users?.lastName}
                    </td>
                    <td className="border border-slate-800  text-left text-slate-800 p-2 text-lg  ">
                      {users?.role?.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <ErrorMessage error={"You haven't invited anyone yet"} />
          )}
        </div>
      )}
    </div>
  );
};

export default InvitedUser;

import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import jwtDecode from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";

import LandingPage from "./pages/LandingPage";
import AuthContext from "./context/AuthContext";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./helper/LocalStorage";
import ClientRoute from "./components/ClientRoute";
import CustomToast from "./components/CustomToast";

import Loader from "./components/Loader";
import { refreshToken } from "./helper/RefreshToken";
import ResetPassword from "./pages/ResetPassword";
import { ErrorHandler } from "./helper/ErrorHandler";
import SideMenu from "./components/SideMenu/SideMenu";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(getLocalStorageItem());

  useEffect(() => {
    const getUserProfile = async () => {
      setLoading(true);
      try {
        if (token) {
          const user = await jwtDecode(token);
          if (user.exp * 1000 < Date.now()) {
            setUser(null);
            setToken(null);
            removeLocalStorageItem();
          } else {
            setUser(user);
            refreshToken();
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        ErrorHandler(error).map((e) => CustomToast("error", e.message));
      }
      setLoading(false);
    };
    getUserProfile();
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {loading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage userLoading={loading} />} />
          <Route
            path="/*"
            element={
              user?.firstName ? (
                <SideMenu>
                  <ClientRoute />
                </SideMenu>
              ) : (
                <LandingPage userLoading={loading} />
              )
            }
          />
          <Route
            path="/resetPassword/:token"
            element={<ResetPassword />}
          ></Route>
        </Routes>
      )}
    </AuthContext.Provider>
  );
}

export default App;

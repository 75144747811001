import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import FormButton from "../../components/CustomForm/FormButton";
import FormContainer from "../../components/CustomForm/FormContainer";
import CustomToast from "../../components/CustomToast";
import ErrorMessage from "../../components/ErrorMessage";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import {
  getLocalStorageItem,
  getUserFromLocalStorage,
} from "../../helper/LocalStorage";
import {
  addRoleModelService,
  getRoleModelService,
} from "../../services/additionalInformationService";
import { ErrorHandler } from "../../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  familyName: Yup.string().required().label("Family Name"),
  nationalName: Yup.string().required().label("National Name"),
  internationalName: Yup.string().required().label("International Name"),
});
const RoleModel = ({ info, setInfo, index }) => {
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [roleModel, setRoleModel] = useState({});

  const handleSubmitRoleModel = async (values) => {
    setPostLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await addRoleModelService(values, token);
      if (data) {
        CustomToast("success", "Role Model added successfully");
        const newArray = [...info];
        newArray[index].opened = false;
        setInfo(newArray);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setPostLoading(false);
  };

  const getRoleModel = async () => {
    setLoading(true);
    try {
      const user = await getUserFromLocalStorage();
      if (user?.id) {
        const token = await getLocalStorageItem();
        const { data } = await getRoleModelService(token, { user_id: user.id });
        if (data) setRoleModel(data[0]);
      } else setRoleModel([]);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoleModel();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : roleModel ? (
        <div>
          <div className="px-2">
            <div className="bg-[#f5e9dc] rounded-md p-2 my-2">
              <p>Family Name: {roleModel?.familyName ?? ""}</p>
              <p>National Name: {roleModel?.nationalName ?? ""}</p>
              <p>International Name: {roleModel?.internationalName ?? ""}</p>
            </div>
          </div>
          <ErrorMessage error={"You have already added your role model"} />
        </div>
      ) : (
        <div className="flex flex-col">
          {postLoading && <Loader />}
          <FormContainer
            width="w-[100%]"
            handleSubmit={handleSubmitRoleModel}
            initialValues={{
              familyName: "",
              nationalName: "",
              internationalName: "",
            }}
            // validationSchema={validationSchema}
          >
            <div className="flex flex-wrap w-full ">
              <FormField
                width="w-[100%] px-5 my-1"
                label="Family Name"
                name="familyName"
                isLogin={true}
                type="text"
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="National Name"
                name="nationalName"
                isLogin={true}
                type="text"
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="International Name"
                name="internationalName"
                isLogin={true}
                type="text"
              />
            </div>
            <FormButton title={"Submit"} />
          </FormContainer>
        </div>
      )}
    </>
  );
};

export default RoleModel;

import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";

import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import { getAllEventService } from "../services/eventService";
import { BASE_URL } from "../config/config";
import { getLocalStorageItem } from "../helper/LocalStorage";
import { ErrorHandler } from "../helper/ErrorHandler";
import CustomToast from "../components/CustomToast";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllEvent();

    return () => {
      setEvents([]);
    };
  }, []);

  const getAllEvent = async () => {
    setLoading(true);
    try {
      const token = await getLocalStorageItem();
      const { data } = await getAllEventService(token);
      if (data?.data) {
        setEvents(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="px-5 md:px-10">
        {loading ? (
          <Loader />
        ) : events?.length ? (
          <div className="flex flex-col">
            {events.map((events, index) => (
              <div
                key={index}
                className="bg-slate-100 border border-slate-200 rounded-md p-5 my-5 flex flex-col lg:flex-row"
              >
                {events?.picture && (
                  <img
                    src={`${BASE_URL}/${events?.picture}`}
                    className="w-[100%] max-w-[500px] max-h-[500px] object-contain lg:w-[40%]"
                  ></img>
                )}
                <div className="lg:ml-10">
                  <p className="text-left text-[18px] md:text-[20px] font-semibold">
                    {events.title}
                  </p>
                  <p className="text-left text-[14px] sm:text-[18px]">
                    {events.description}
                  </p>
                  <p className="text-left bg-[#e79e18] p-2 text-[white] rounded-md w-max font-mono text-[18px] ">
                    {moment(events.date).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ErrorMessage error={"No events found"} />
        )}
      </div>
    </>
  );
};

export default EventsPage;

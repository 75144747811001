import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { BsFillInfoCircleFill } from "react-icons/bs";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Typography from "@mui/material/Typography"

import CustomToast from "../../components/CustomToast";
import CustomErrorMessage from "../../components/ErrorMessage";
import { registerService } from "../../services/userService";
import { getLocalStorageItem } from "../../helper/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllBankService, useBank } from "../../services/bankService";
import { getRoleInformationService, useRole } from "../../services/roleService";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import FormImage from "../../components/CustomForm/FormImage";
import FormButton from "../../components/CustomForm/FormButton";
import FormField from "../../components/CustomForm/FormField";
import Loader from "../../components/Loader";
import { ErrorHandler } from "../../helper/ErrorHandler";

const validationSchema = Yup.object().shape({
  bank: Yup.string().required().label("Bank"),
  paymentDate: Yup.date().required().label("Payment Date"),
  paymentReferenceNo: Yup.string().required().label("Payment Reference Number"),
  motherFullName: Yup.string().required().label("Mother Full Name"),
  birthDate: Yup.date().required().label("Birth Date"),
  tinNumber: Yup.string()
    .test(
      "len",
      "Tin number must be 10 digits",
      (val) => val?.toString().length === 10
    )
    .required()
    .label("Tin Number"),
  profilePicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only image is allowed",
      (value) =>
        !value ||
        (value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 2 Mega Bytes",
      (value) => !value || (value && value.size <= 2000000)
    )
    .label("Profile Picture"),
  idPicture: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only image is allowed",
      (value) =>
        !value ||
        (value && ["image/png", "image/jpg", "image/jpeg"].includes(value.type))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 5 Mega Bytes",
      (value) => !value || (value && value.size <= 5000000)
    )
    .label("Id/ Passport Picture "),
});
function SignUpForG4() {
  const { state } = useLocation();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const navigate = useNavigate();

  // const {
  //   data: role,
  //   error,
  //   loading,
  // } = useRole(`role/${state?.roleId}`, state?.roleId);

  // const {
  //   data: bank,
  //   error: bankError,
  //   loading: bankLoading,
  // } = useBank(state?.roleId);
  
  
  const [bank, setBank] = useState([]);
  const [role, setRole] = useState({});
  
  const [bankLoading, setBankLoading] = useState(false);
  const [loading, setRoleLoading] = useState(false);


  useEffect(() => {
    getAllBank();
    getRoleInformation();
  }, [state?.roleId]);

  const getAllBank = async () => {
    setBankLoading(true);
    try {
      const { data } = await getAllBankService();
      if (data.data) setBank(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setBankLoading(false);
  };

  const getRoleInformation = async () => {
    setRoleLoading(true);
    try {
      const { data } = await getRoleInformationService(state?.roleId);
      if (data.data) {
        setRole(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setRoleLoading(false);
  };

 const handleRegister = async (values) => {
   setUploadLoading(true);
   values.role = role?.id;
   const formData = new FormData();

   Object.keys(values).forEach((items) =>
     formData.append(items, values[items])
   );
   const token = getLocalStorageItem();

   try {
     const { data } = await registerService(formData, token, 4, (progress) => {
       setProgressVisible(true);
       setProgress(Math.ceil(progress * 100));
       setProgressVisible(false);
     });
     if (data) {
       CustomToast("success", "You have registered for the next role");
       navigate("/", { replace: true });
     }
   } catch (error) {
     ErrorHandler(error).map((e) => CustomToast("Err", e.message));
   }
   setUploadLoading(false);
 };

  return (
    <div className="flex w-full flex-1 justify-center">
      {loading || bankLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full items-center">

          <div className="flex items-center mt-5">
            <NewReleasesIcon
              color="success"
              style={{ fontSize: 40 }}
            ></NewReleasesIcon>
            <Typography color={"green"} fontSize={20}>
              You will be a shareholder when you successfully complete this form
            </Typography>
          </div>
          {role && bank.length ? (
            <Formik
              initialValues={{
                paymentAmount: role?.fee,
                bank: "",
                paymentDate: moment(),
                paymentReferenceNo: "",
                motherFullName: "",
                tinNumber: "",
                birthDate: moment(),
                profilePicture: "",
                idPicture: "",
              }}
              onSubmit={(values) => {
                values.paymentAmount = role?.fee;
                handleRegister(values);
              }}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values }) => (
                <Form className="flex my-5 flex-wrap w-[90%]  shadow-lg rounded-lg">
                  {(() => {
                    const selectedBank =
                      bank[
                        bank.findIndex((banks) => banks?.name === values?.bank)
                      ];

                    return (
                      <div className="alert badge-warning flex justify-center items-center rounded-bl-[0] rounded-br-[0]">
                        <BsFillInfoCircleFill size={20} color="white" />
                        <p className="text-center text-[whitesmoke]">
                          Pay{" "}
                          <span className="text-lg font-semibold">
                            {role?.fee}
                          </span>{" "}
                          ETB
                          {values.bank &&
                            " using " + selectedBank?.name + " account number "}
                          <span className="font-semibold">
                            {selectedBank?.accountNumber}
                          </span>
                        </p>
                      </div>
                    );
                  })()}

                  <FormDropDown
                    data={bank}
                    label={"Bank"}
                    name={"bank"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <FormField
                    label="Payment Reference Number"
                    name="paymentReferenceNo"
                    type="text"
                    placeholder="Payment Reference Number"
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <FormDatePicker
                    name="paymentDate"
                    label={"Payment Date"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <FormField
                    label="Mother Full Name"
                    name="motherFullName"
                    type="text"
                    placeholder="Mother FullName"
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <FormDatePicker
                    name="birthDate"
                    label={"Date of Birth"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />
                  <FormField
                    label="Tin Number"
                    name="tinNumber"
                    type="text"
                    placeholder="Tin Number"
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                  />

                  <FormImage
                    label={"Profile Picture"}
                    name={"profilePicture"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                    progress={progress}
                    progressVisible={progressVisible}
                  />
                  <FormImage
                    label={"Passport / National Id Picture"}
                    name={"idPicture"}
                    width={"w-[100%] md:w-[50%] md:px-5 my-1"}
                    progress={progress}
                    progressVisible={progressVisible}
                  />
                  <div className="w-full flex justify-end">
                    <FormButton
                      handleClick={handleSubmit}
                      loading={bankLoading || progressVisible || uploadLoading}
                      title={"Upgrade"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <CustomErrorMessage error={"Error occurred, Please try again"} />
          )}
        </div>
      )}
    </div>
  );
}

export default SignUpForG4;

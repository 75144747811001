import axios from "axios";
import { URL } from "../config/config";

import { useData } from "../hooks/useData";
import { header } from "../helper/APIToken";

export const getRoleInformationService = (id) => {
  return axios.get(`${URL}role/${id}`, header());
};
export const getAllRoleService = () => {
  return axios.get(`${URL}roles`, header());
};

// export const useRole = (endpoint,id, deps) => useData(`${endpoint}/${id}`, header(), deps);
export const useRole = (endpoint, deps) => useData(endpoint, header(), deps);

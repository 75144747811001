import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";

import * as Yup from "yup";

import { useRole } from "../../services/roleService";
import { useBank } from "../../services/bankService";
import { useField } from "../../services/fieldService";
import Loader from "../../components/Loader";
import FormButton from "../../components/CustomForm/FormButton";
import { MdPriorityHigh } from "react-icons/md";
import G2 from "./Roles/G2";
import G3 from "./Roles/G3";
import CustomToast from "../../components/CustomToast";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { addUserByAgentService } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const validationSchema = Yup.object().shape({
  role: Yup.mixed().required().label("role"),
  firstName: Yup.string().required().label("First Name"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  lastName: Yup.string().required().label("Father Name"),
  grandFatherName: Yup.string().required().label("Grand Father Name"),
  country: Yup.string().required().label("Country"),
  state: Yup.string().required().label("State"),
  city: Yup.string().required().label("City"),
  university: Yup.string().required().label("Education Level"),
  field_id: Yup.string().required().label("Field"),
  yearGraduated: Yup.string().required().label("Year graduated"),
  referal: Yup.string().required().label("Referral"),
  referalCode: Yup.string().when("referal", {
    is: "agent",
    then: Yup.string().required().label("Referal code"),
    otherwise: Yup.string().nullable(),
  }),
  serialKey: Yup.number()
    .label("Key")
    .when("referal", {
      is: "agent",
      then: Yup.number()
        .test(
          "len",
          "Key must be 10 digits",
          (val) => val?.toString().length == 10
        )
        .required(),
      otherwise: Yup.number(),
    }),
  document: Yup.mixed().when("role", {
    is: "3",
    then: Yup.mixed()
      .required()
      .test(
        "FILE_TYPE",
        "Invalid file type, Only images and pdf is allowed",
        (value) =>
          !value ||
          (value &&
            [
              "image/png",
              "image/jpg",
              "image/jpeg",
              "application/pdf",
            ].includes(value.type))
      )
      .test(
        "FILE_SIZE",
        "File size is too large, please upload file less than 5 Mega Bytes",
        (value) => !value || (value && value.size <= 5000000)
      )
      .label("Document"),
    otherwise: Yup.mixed(),
  }),
  bank: Yup.string().when("role", {
    is: "3",
    then: Yup.string().required().label("Bank"),
    otherwise: Yup.string().notRequired()
  }),
  paymentDate: Yup.string().when("role", {
    is: "3",
    then: Yup.string().required().label("Payment Date"),
    otherwise: Yup.string().notRequired()
  }),
  paymentReferenceNo: Yup.string().when("role", {
    is: "3",
    then: Yup.string().required().label("Payment Reference"),
    otherwise: Yup.string().notRequired()
  }),
  
});

const AddUser = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const { data, error, fetchData, loading } = useRole("roles");
  const {
    data: banks,
    error: bankError,
    fetchData: fetchBanks,
    loading: bankLoading,
  } = useBank("");
  const {
    data: field,
    error: fieldError,
    fetchData: fetchFields,
    loading: fieldLoading,
  } = useField("");

  const initialValue = {
    firstName: "",
    email: "",
    phoneNumber: "",
    password: "",
    lastName: "",
    grandFatherName: "",
    country: "",
    state: "",
    city: "",
    university: "",
    field_id: "",
    yearGraduated: moment(),
    document: [],
    referal: "",
    referalCode: "",
    serialKey: 0,
    bank: "",
    paymentDate: moment(),
    paymentReferenceNo: "",
    role: null,
  };

  const clearProgress = () => {
    setProgressVisible(false);
    setProgress(0);
  };

  const handleRegister = async (values) => {
    const formData = new FormData();
    values.role_id = selectedRole;
    values[`upgradeDateForG${selectedRole}`] = new Date().toISOString();
    values.paymentAmount = data.find((role) => role.id == selectedRole)?.fee;

    Object.keys(values).forEach((item) => formData.append(item, values[item]));
    setRegisterLoading(true);
    try {
      const { data } = await addUserByAgentService(formData, (progress) => {
        setProgressVisible(true);
        setProgress(Math.ceil(progress * 100));
      });
      if (data) {
        CustomToast("success", "User Added Successfully");
        clearProgress();
        navigate("/")
      }
    } catch (error) {
      clearProgress();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setRegisterLoading(false);
  };

  return (
    <div className="flex flex-col w-full px-10 items-center min-h-[400px]">
      {registerLoading && <Loader />}
      {bankLoading || loading || fieldLoading ? (
        <div className="flex w-full justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <select
            onChange={(e) => setSelectedRole(e.currentTarget.value)}
            className="mt-10 select select-primary w-full max-w-xs"
          >
            <option value={""}>Select role</option>
            {data
              ?.slice(1, 3)
              .map((items) => (
                <option key={items.id} value={items.id}>
                  {items.name}
                </option>
              ))}
          </select>
          {selectedRole ? (
            <Formik
              initialValues={initialValue}
              onSubmit={handleRegister}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, values, errors }) => (
                <>
                  {console.log("err", errors, values)}
                  <Form
                    className={`w-full p-2.5 justify-between flex flex-wrap `}
                  >
                    <G2
                      role={selectedRole}
                      country={values.country}
                      field={field}
                    ></G2>

                    {selectedRole === "3" && (
                      <G3
                        progress={progress}
                        progressVisible={progressVisible}
                        bank={banks}
                      />
                    )}

                    <div className="w-full flex justify-end mt-5">
                      <FormButton
                        title={"Add User"}
                        isSubmitting={
                          isSubmitting ||
                          registerLoading ||
                          progressVisible ||
                          fieldLoading ||
                          bankLoading ||
                          loading
                        }
                      />
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          ) : (
            <>
              <MdPriorityHigh color="purple" size={"80"} />
              <p className="font-semibold">Please Select a Role</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AddUser;

import * as React from "react";
import Popover from "@mui/material/Popover";

import { Box, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";


export default function CustomPopover({ children,title }) {
  const [anchorEl, setAnchorEl] = React.useState(
    null
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {title ? (
        <p onClick={handleClick} className="text-slate-500 text-[14px] md:text-[20px] font-semibold">
          {title}
        </p>
      ) : (
        <IconButton onClick={handleClick} aria-label="settings">
          <MoreVert />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ padding: 1, display: "flex", flexDirection: "column" }}>
          {children}
        </Box>
      </Popover>
    </>
  );
}

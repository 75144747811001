import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import Header from "../components/Header";
import FormField from "../components/CustomForm/FormField";
import CustomToast from "../components/CustomToast";
import AuthContext from "../context/AuthContext";

import { changePasswordService } from "../services/userService";
import Loader from "../components/Loader";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../helper/LocalStorage";
import { ErrorHandler } from "../helper/ErrorHandler";

function ChangePassword() {
  const authContext = useContext(AuthContext);
  const { setUser } = authContext;
  const [loading, setLoading] = useState();
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required().label("Old Password"),
    newPassword: Yup.string().required().label("New Password"),
    rePassword: Yup.string().required().label("Re-enter Password"),
  });

  const handleChangePassword = async (values) => {
    setLoading(true);
    const value = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    if (values.newPassword !== values.rePassword) {
      CustomToast(
        "error",
        "Your new passwords don't match, please check again"
      );
      setLoading(false);
    } else {
      try {
        const token = getLocalStorageItem();
        const { data } = await changePasswordService(token, value);
        if (data.data) {
          removeLocalStorageItem();
          setUser({});
          window.location = "/";
          CustomToast("success", data.data);
        }
      } catch (error) {
        ErrorHandler(error).map((e) => CustomToast("Err", e.message));
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col min-h-[calc(100vh-80px)] justify-center items-center">
        <p className="text-center font-semibold my-5 md:text-[16px] lg:text-[18px] w-full">
          Change Password
        </p>
        {loading && <Loader />}
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            rePassword: "",
          }}
          onSubmit={(values) => {
            handleChangePassword(values);
          }}
          validationSchema={validationSchema}
        >
          {() => (
            <Form className="flex flex-col max-w-[600px] bg-[#f8f8f8] shadow-xl flex-wrap w-[100%] sm:w-[80%] md:w-[60%] lg:w-[50%] items-center rounded-lg pt-5">
              <FormField
                width="w-[100%] px-5 my-1"
                label="Old Password"
                name="oldPassword"
                type="password"
                style={{ width: "" }}
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="New Password"
                name="newPassword"
                type="password"
              />
              <FormField
                width="w-[100%] px-5 my-1"
                label="Re-enter Password"
                name="rePassword"
                type="password"
              />
              <div className="flex flex-col justify-center my-5 items-center w-full">
                <button
                  type="submit"
                  className="bg-[#232536] px-10 py-2 xs:w-[90%] md:w-[50%] rounded-lg text-white"
                >
                  Change Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;

import React, { useState } from "react";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import FormButton from "../../components/CustomForm/FormButton";
import {
  resetPasswordService,
  submitEmailService,
} from "../../services/userService";
import CustomToast from "../../components/CustomToast";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string().required().label("Password"),
    reEnterPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await resetPasswordService(token, value);
      if (data.message) {
        CustomToast("success", data.message);
        navigate("https://keyug.com");
        // window.location = "http://keyug.com";
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="w-full min-h-screen items-center flex justify-center">
      <FormContainer
        handleSubmit={handleSubmit}
        initialValues={{ password: "", reEnterPassword: "" }}
        validationSchema={validationSchema}
        width={"w-[90%] md:w-[50%] max-w-[500px]"}
      >
        <FormField
          width="w-[100%]  px-5 my-1"
          label="Password"
          name="password"
          type="text"
          placeholder="Password"
        />
        <FormField
          width="w-[100%] px-5 my-1"
          label="Re-enter Password"
          name="reEnterPassword"
          type="text"
          placeholder="Re-enter Password"
        />
        <FormButton loading={loading} title={"Submit"} />
      </FormContainer>
    </div>
  );
};

export default ResetPassword;

import axios from "axios";
import { URL } from "../config/config";
import { header } from "../helper/APIToken";
import { useData } from "../hooks/useData";


export const getShareForRoleService = async (id, token) => {
  return await axios.get(`${URL}share/${id}`, {
    headers: { "x-auth-token": token },
  });
};
export const editShareService = async (id, amount, percentage) => {
  return await axios.put(`${URL}editShare/${id}`, { amount, percentage });
};
export const addShareService = async (value) => {
  return await axios.post(`${URL}addShare`, value);
};

export const MyShareService = async (shareId, token) => {
  return await axios.get(`${URL}userShare/mine/${shareId}`, {
    headers: { "x-auth-token": token },
  });
};


export const useShare = (id, deps) => useData(`share/${id}`, header(), deps);
import React, { useContext, useEffect, useRef, useState } from "react";

import People from "../assets/peoples.png";
import CustomCarousel from "../components/CustomCarousel";
import Menu from "../assets/menu.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { getBannerAndTotalUserService } from "../services/userService";

import AuthContext from "../context/AuthContext";

import { ErrorHandler } from "../helper/ErrorHandler";
import LoginPage from "./LoginPage";
import SignUp from "./SignUp/SignUp";
import Message from "./Message";
import ForgotPassword from "./ForgetPassword";

function LandingPage({ userLoading }) {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalForLogin, setShowModalForLogin] = useState(false);
  const [showModalForSignup, setShowModalForSignup] = useState(false);
  const [showModalForForgot, setShowModalForForgot] = useState(false);
  const [userLength, setUserLength] = useState(0);
  const [msgModal, setMsgModal] = useState(false);
  const { user } = useContext(AuthContext);

  const about = [
    { name: "100+ Members" },
    { name: "Work Together" },
    { name: "Promote" },
    { name: "Own Share" },
  ];
  const steps = [
    {
      index: 1,
      name: "REGISTER",
      detail:
        "Register and become part of a solution in the system corporate. You will be given priority over unregistered ones. Early registration has more advantages.",
    },
    {
      index: 2,
      name: "TEAM UP",
      detail:
        "Participate and create a team to understand and support each other with open communication and discussion. Consider multiple options for better achievements. Benefit from a creative and entrepreneurship systems team work that go far beyond individual activity and success.",
    },
    {
      index: 3,
      name: "BE MEMBER",
      detail:
        "Enjoy membership privileges & opportunities. Become member shareholder to get profit share, entrepreneurship fund and job opportunity priority.",
    },
    {
      index: 4,
      name: "UPGRADE",
      detail:
        "Upgrade from being a member to becoming shareholder and entrepreneur to be more beneficiary of systems corporate.",
    },
    {
      index: 5,
      name: "PROMOTE ",
      detail:
        "Inform and enable others to join us to get promotion incentives, corporate share and product sells agency benefits.",
    },
    {
      index: 6,
      name: "BECOME SHAREHOLDER",
      detail:
        "Get shareholders profit share, privileges and opportunities. Be entitled to entrepreneurship idea development and financing with best job opportunities with in the corporate and partners. ",
    },
  ];

  const registerRef = useRef(null);
  const aboutRef = useRef(null);
  const homeRef = useRef(null);
  const activityRef = useRef(null);
  const modalRef = React.createRef(null);

  const executeScroll = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  const renderButton = () => {
    if (user?.id || userLoading) return null;
    return (
      <button
        onClick={() => setShowModalForLogin(true)}
        className="bg-gradient-to-r from-indigo-500 via-indigo-400 to-indigo-200 text-white rounded-[30px] px-6 py-2 md:px-10 md:py-3 text-[16px] md:text-[20px] w-[100px] md:w-[200px]"
      >
        Login
      </button>
    );
  };

  useEffect(() => {
    let isMounted = true;
    const getBanner = async () => {
      setLoading(true);
      try {
        const { data } = await getBannerAndTotalUserService();
        if (isMounted && data.data) {
          setBanner(data.data.banner);
          setUserLength(data.data.userLength);
        }
      } catch (error) {
        // ErrorHandler(error).map((e) => CustomToast("error", e.message));
      }
      setLoading(false);
    };
    getBanner();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="min-h-screen pb-[200px]">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header
            onBackClick={executeScroll}
            ref={[homeRef, aboutRef, registerRef, activityRef]}
          />
          <div className="flex flex-col">
            <div
              ref={homeRef}
              className="bg-[#232536] sm:min-h-[10vh] md:min-h-[40vh] p-10 flex flex-row"
            >
              <div className="flex flex-col flex-1 lg:flex-[0.5] items-center ">
                <div>
                  <h3 className="text-white text-[25px] sm:text-[50px] flex flex-wrap font-">
                    Let us work together
                  </h3>
                  <span className="text-white text-[25px] sm:text-[50px]  flex flex-wrap">
                    for victorious endeavour
                  </span>
                  <p className="text-slate-400  text-[15px] sm:text-[20px] my-4">
                    A simple platform for finding your career path.
                  </p>
                  {renderButton()}
                </div>
              </div>
              <div className="flex-[0.5] hidden lg:flex">
                <img
                  src={People}
                  style={{ width: "100%", height: "auto", flex: 1 }}
                  className="object-contain"
                ></img>
              </div>
            </div>
            <div
              ref={aboutRef}
              className="flex flex-col bg-[#dcdcdc] w-[100%] min-h-[50vh] justify-center"
            >
              <div className="w-[80%] flex flex-col self-center my-2">
                <h3 className="font-semibold text-[16px] dm:text-[18px]">
                  ABOUT US
                </h3>
                <p className="text-[14px] md:text-[16px]">
                  KeyUG is developed by ETAMB PLC for the establishment of
                  volunteer, value oriented visionaries systems corporate to
                  create an internationally recognized creativity &
                  entrepreneurship environment in addition to taking part in the
                  national development program. KeyUG is a step in to being
                  authenticated, dedicated, excellent and trustworthy
                  professional with a healthy, peaceful & loving mindset seeing
                  a safe, stable & sustainable system.
                </p>
                <div className="flex flex-col md:flex-row mt-[20px] w-[100%] self-center justify-between">
                  {about.map((inf, index) => (
                    <div
                      key={index}
                      className={`flex w-[100%] md:w-[24%] h-[70px] sm:h-[100px] lg:h-[180px] my-2 rounded-md justify-center items-center ${
                        index % 2 == 0 ? "bg-[#f7f0de]" : "bg-[#efeeee]"
                      } ${
                        index == 0
                          ? user?.role >= 4
                            ? "block"
                            : "hidden"
                          : null
                      }`}
                    >
                      <p className=" text-center text-[14px] md:text-[16px]">
                        {index == 0
                          ? user?.role >= 4
                            ? `${userLength ? userLength : 100}+ members`
                            : null
                          : inf.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              ref={activityRef}
              className="flex flex-col h-max justify-center w-full"
            >
              {banner.length > 0 ? <CustomCarousel images={banner} /> : null}
            </div>

            <div
              ref={registerRef}
              className="flex flex-col w-[100%] min-h-[50vh] bg-[#E6E6F6] justify-center"
            >
              <div className="w-[80%] flex flex-col self-center my-2">
                <h3 className="font-semibold">
                  REGISTER AND BECOME OUR PARTNER
                </h3>
                <h3 className="font-semibold">How do we work ?</h3>
                <div className="flex flex-col md:flex-row flex-wrap justify-between">
                  {steps.map((step, index) => (
                    <div
                      key={index}
                      className="flex flex-col border-slate-800 border-solid border-[0.5px] p-[10px] w-full rounded-md md:w-[30%] lg:w-[25%] m-2"
                    >
                      <div className="flex flex-row justify-between items-center my-2">
                        <p className="text-[14px] lg:text-[18px] font-medium">
                          {step.name}
                        </p>
                        <p className="font-semibold text-[#232536] p-1 rounded-md border-slate-300 border-solid border-[3px]">
                          {step.index}
                        </p>
                      </div>
                      <p className="text-[#232536] text-[12px]">
                        {step.detail}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {user?.id && (
              <div
                onClick={() => setMsgModal(!msgModal)}
                className="flex flex-col justify-center items-center bg-[#232536] rounded-[10px] h-[40px] w-[40px] fixed bottom-5 right-5 z-10 hover:text-[black] text-[white]"
              >
                <img src={Menu} width={20}></img>
              </div>
            )}
            <div className="w-full h-[200px]">
              <Footer
                onBackClick={executeScroll}
                ref={[homeRef, aboutRef, registerRef, activityRef]}
              />
            </div>
          </div>
          <Message msgModal={msgModal} setMsgModal={setMsgModal} />
        </>
      )}
      <LoginPage
        ref={modalRef}
        showModal={showModalForLogin}
        setShowModalForSignup={setShowModalForSignup}
        setShowModal={setShowModalForLogin}
        setShowModalForForgot={setShowModalForForgot}
      />
      <SignUp
        ref={modalRef}
        showModal={showModalForSignup}
        setShowModalForLogin={setShowModalForLogin}
        setShowModal={setShowModalForSignup}
      />
      <ForgotPassword
        // ref={modalRef}
        setShowModalForForgot={setShowModalForForgot}
        setShowModal={setShowModalForLogin}
        showModalForForgot={showModalForForgot}
      />
    </div>
  );
}

export default LandingPage;

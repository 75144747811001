import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomToast from "../components/CustomToast";
import ListInformation from "../components/ListInformation";
import Loader from "../components/Loader";
import { BASE_URL, URL } from "../config/config";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../helper/LocalStorage";
import { getUserInformationService } from "../services/userService";
import { ErrorHandler } from "../helper/ErrorHandler";

const ProfilePage = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    try {
      const token = getLocalStorageItem();
      const { data } = await getUserInformationService(token);
      if (data.data) {
        setUser(data.data);
      }
    } catch (error) {
      removeLocalStorageItem();
      window.location = "/";
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col mb-5">
          <div className="flex flex-col w-[100%] self-center">
            <div className="w-[100%] flex self-end justify-end flex-col md:flex-row">
              <Link
                to="/editProfile"
                className="bg-[#144914] text-white inline w-full md:w-max md:min-w-[200px] text-center text-[14px] md:text-[16px] p-[2.5px] my-[5px] md:p-1.5 md:my-2 md:mx-2  rounded-md "
              >
                Edit Profile
              </Link>
              <Link
                to="/additionalInformation"
                className="bg-[#29294a] text-white inline w-full md:w-max md:min-w-[200px] text-center text-[14px] md:text-[16px] p-[2.5px] my-[5px] md:p-1.5 md:my-2 md:mx-2  rounded-md "
              >
                Add additional information
              </Link>
              {user.role_id >= 4 ? (
                <Link
                  to="/assignKey"
                  className="bg-[purple] text-white inline w-full md:w-max md:min-w-[200px] text-center text-[14px] md:text-[16px] p-[2.5px] my-[5px] md:p-1.5 md:my-2 md:mx-2  rounded-md "
                >
                  Assign key to user
                </Link>
              ) : null}
            </div>

            <div className="flex flex-col flex-1 md:flex-row items-center w-full ">
              {user?.profilePicture && (
                <>
                  <img
                    alt="profile"
                    src={`${BASE_URL + user?.profilePicture}`}
                    className="w-[250px] h-[200px] md:h-[250px] md:w-[350px] object-contain"
                  />
                  <p className="mx-2 capitalize text-left w-full font-semibold sm:text-[15px] md:text-[16px] lg:text-[20px] text-[#427287]">
                    {user?.firstName + " " + user?.lastName}
                  </p>
                </>
              )}
            </div>
            {/* <p className="text-left text-[18px] text-slate-800">My Profile</p> */}
            <div className="flex flex-col w-[100%]  self-center md:flex-row flex-wrap justify-between">
              {user?.firstName && !user?.profilePicture && (
                <ListInformation
                  disabled
                  label="Name"
                  value={user?.firstName}
                />
              )}
              {user?.lastName && !user?.profilePicture && (
                <ListInformation
                  disabled
                  label="Last Name"
                  value={user?.lastName}
                />
              )}
              {user?.grandFatherName && (
                <ListInformation
                  disabled
                  label="Grand Father Name"
                  value={user?.grandFatherName}
                />
              )}
              <ListInformation
                disabled
                label="Email"
                name="email"
                value={user?.email}
              />
              {user?.phoneNumber && (
                <ListInformation
                  disabled
                  label="Phone Number"
                  name="phoneNumber"
                  value={user?.phoneNumber}
                />
              )}
              {user?.country && (
                <ListInformation
                  disabled
                  label="Country"
                  name="country"
                  value={user?.country}
                />
              )}
              {user?.city && (
                <ListInformation
                  disabled
                  label="City"
                  name="city"
                  value={user?.city}
                />
              )}
              {user?.birthDate && (
                <ListInformation
                  disabled
                  label="Birth Date"
                  name="birthDate"
                  value={new Date(user?.birthDate).toDateString()}
                />
              )}
              {user?.state && (
                <ListInformation
                  disabled
                  label="Sate"
                  name="state"
                  value={user?.state}
                />
              )}
              {user?.tinNumber && (
                <ListInformation
                  disabled
                  label="Tin Number"
                  name="tinNumber"
                  value={user?.tinNumber}
                />
              )}
              {user?.motherFullName && (
                <ListInformation
                  disabled
                  label="Mother Full Name"
                  name="motherFullName"
                  value={user?.motherFullName}
                />
              )}
              {user?.role?.name && (
                <ListInformation
                  disabled
                  label="Current Role"
                  name="otherFullName"
                  value={user?.role?.name}
                />
              )}
              {user?.idPicture && (
                <div className="flex flex-col w-full md:w-[45%]">
                  <p className="text-left text-[14px] md:text-[16px] text-slate-500">
                    ID Picture
                  </p>
                  <img
                    alt="Id"
                    src={`${BASE_URL + user?.idPicture}`}
                    className="h-[250px] w-[100%] md:w-[350px] object-contain"
                  />
                </div>
              )}
              {user?.document && (
                <div className="flex flex-col cursor-pointer w-full  md:w-[45%]">
                  {user.document.slice(-3) == "pdf" ? (
                    <a
                      target={"_blank"}
                      className="w-[100%]"
                      href={`${BASE_URL}${user.document}`}
                    >
                      <ListInformation
                        disabled
                        label="Document"
                        name="document"
                        value={"View document"}
                      />
                    </a>
                  ) : (
                    <div>
                      <p className="text-left text-[18px] text-slate-500">
                        Document
                      </p>
                      <img
                        alt="Image"
                        src={`${BASE_URL + user?.document}`}
                        className="h-[250px] w-[100%] md:w-[350px] object-contain"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;

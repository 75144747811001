import React, { useState } from "react";
import { Link, useNavigation, useNavigate } from "react-router-dom";

import Right from "../../assets/right.png";
import Down from "../../assets/down.png";
import CompletedService from "./CompletedService";
import CurrentJob from "./CurrentJob";
import Skill from "./Skill";
import Volunteer from "./Volunteer";
import RoleModel from "./RoleModel";
import EmergencyContact from "./EmergencyContact";
import PersonalInformation from "./PersonalInformation";

const AdditionalInformation = () => {
  const [info, setInfo] = useState([
    { name: "Current Job", to: "currentJob", opened: false },
    { name: "Completed Service", to: "completedService", opened: false },
    { name: "Skills", to: "skill", opened: false },
    {
      name: "Volunteer and Social Free Services",
      to: "volunteer",
      opened: false,
    },
    { name: "Role Model", to: "roleModel", opened: false },
    { name: "Emergency Contact", to: "", opened: false },
    { name: "Personal Information", to: "", opened: false },
  ]);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <p className="text-[#050505] w-full p-5 mt-5 bg-[#c9e2c9] text-[14px] sm:text-[18px] font-semibold  ">
        Add the following information
      </p>
      <div className="flex flex-col my-5 w-[100%] px-1 sm:px-0 sm:w-[90%] md:w-[80%]">
        {info.map((infos, index) => (
          <div key={index}>
            <button
              onClick={() => {
                const newArray = [...info];
                newArray[index].opened = !newArray[index].opened;
                setInfo(newArray);
              }}
              key={index}
              className="flex justify-between w-[100%] my-2 p-5 shadow rounded-lg hover:bg-[#f4f4f4] bg-[white]"
            >
              <p className="text-[#3b3b3b]">{infos.name}</p>
              <img
                src={infos.opened ? Down : Right}
                width={20}
                height={10}
              ></img>
            </button>
            {infos?.opened && index == 0 && (
              <CurrentJob info={info} setInfo={setInfo} index={0} />
            )}
            {infos?.opened && index == 1 && (
              <CompletedService info={info} setInfo={setInfo} index={1} />
            )}
            {infos?.opened && index == 2 && (
              <Skill info={info} setInfo={setInfo} index={2} />
            )}
            {infos?.opened && index == 3 && (
              <Volunteer info={info} setInfo={setInfo} index={3} />
            )}
            {infos?.opened && index == 4 && (
              <RoleModel info={info} setInfo={setInfo} index={4} />
            )}
            {infos?.opened && index == 5 && (
              <EmergencyContact info={info} setInfo={setInfo} index={5} />
            )}
            {infos?.opened && index == 6 && (
              <PersonalInformation data={info} setData={setInfo} index={6} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalInformation;

import axios from "axios";
import { URL } from "../config/config";
import { useData } from "../hooks/useData";
import { header } from "../helper/APIToken";

export const getAllFieldService = (token) => {
  return axios.get(`${URL}fields`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const addFieldService = (name, token) => {
  return axios.post(`${URL}field`, name, {
    headers: {
      "x-auth-token": token,
    },
  });
};


export const useField = (id, deps) => useData(`fields`, header(), deps);
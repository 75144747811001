import React, { useState } from "react";
import * as Yup from "yup";

import FormContainer from "../../components/CustomForm/FormContainer";
import FormField from "../../components/CustomForm/FormField";
import CustomModal from "../../components/CustomModal";
import FormButton from "../../components/CustomForm/FormButton";
import { submitEmailService } from "../../services/userService";
import CustomToast from "../../components/CustomToast";
import { ErrorHandler } from "../../helper/ErrorHandler";

const ForgotPassword = ({ setShowModalForForgot, showModalForForgot, ref }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label("Email"),
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value) => {
    setLoading(true);
    try {
      const { data } = await submitEmailService(value);
      if (data.data) {
        CustomToast("success", data.data);
        setShowModalForForgot(false);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setLoading(false);
  };

  return (
    <div className="w-full">
      {showModalForForgot && (
        <CustomModal
          onClose={() => setShowModalForForgot(false)}
          loading={loading}
          // ref={ref}
          title={"Forgot Password"}
        >
          <FormContainer
            handleSubmit={handleSubmit}
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            width={"w-[100%]"}
          >
            <FormField
              width="w-[100%] px-5 my-1"
              label="Email"
              name="email"
              type="text"
              placeholder="Email"
            />
            <FormButton loading={loading} title={"Submit"} />
          </FormContainer>
        </CustomModal>
      )}
    </div>
  );
};

export default ForgotPassword;

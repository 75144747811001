import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";

import { registerService } from "../../services/userService";
import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import { getLocalStorageItem } from "../../helper/LocalStorage";
import { getAllBankService, useBank } from "../../services/bankService";

import { useLocation, useNavigate } from "react-router-dom";
import { getRoleInformationService, useRole } from "../../services/roleService";
import FormDatePicker from "../../components/CustomForm/FormDatePicker";
import FormDropDown from "../../components/CustomForm/FormDropDown";
import FormButton from "../../components/CustomForm/FormButton";
import FormField from "../../components/CustomForm/FormField";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { BsFillInfoCircleFill } from "react-icons/bs";
import FormImage from "../../components/CustomForm/FormImage";

const validationSchema = Yup.object().shape({
  bank: Yup.string().required().label("Bank"),
  paymentDate: Yup.date().required().label("Payment Date"),
  paymentReferenceNo: Yup.string().required().label("Payment Reference Number"),
  document: Yup.mixed()
    .required()
    .test(
      "FILE_TYPE",
      "Invalid file type, Only images and pdf is allowed",
      (value) =>
        !value ||
        (value &&
          ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
            value.type
          ))
    )
    .test(
      "FILE_SIZE",
      "File size is too large, please upload file less than 5 Mega Bytes",
      (value) => !value || (value && value.size <= 5000000)
    )
    .label("Document"),
});
function SignUpForG3() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressVisible, setProgressVisible] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  // const {
  //   data: bank,
  //   error: bankError,
  //   loading: bankLoading,
  // } = useBank(state?.roleId);

  // const {
  //   data: role,
  //   error,
  //   loading,
  // } = useRole(`/role/${state?.roleId}`, [state]);


  const [bank, setBank] = useState([]);
  const [role, setRole] = useState({});
  
  const [bankLoading, setBankLoading] = useState(false);
  const [loading, setRoleLoading] = useState(false);


  useEffect(() => {
    getAllBank();
    getRoleInformation();
  }, [state?.roleId]);

  const getAllBank = async () => {
    setBankLoading(true);
    try {
      const { data } = await getAllBankService();
      if (data.data) setBank(data.data);
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setBankLoading(false);
  };

  const getRoleInformation = async () => {
    setRoleLoading(true);
    try {
      const { data } = await getRoleInformationService(state?.roleId);
      if (data.data) {
        setRole(data.data);
      }
    } catch (error) {
      ErrorHandler(error).map((e) => CustomToast("Err", e.message));
    }
    setRoleLoading(false);
  };




  const handleRegister = async (values) => {
    setSubmitLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((items) =>
      formData.append(items, values[items])
    );

    const token = getLocalStorageItem();
    try {
      const { data } = await registerService(formData, token, 3, (progress) => {
        setProgressVisible(true);
        setProgress(Math.ceil(progress * 100));
        setProgressVisible(false);
      });
      if (data) {
        CustomToast("success", "You have registered for the next role");
        navigate("/", { replace: true });
        clearProgress();
      }
    } catch (error) {
      clearProgress();
      ErrorHandler(error).map((e) => CustomToast("error", e.message));
    }
    setSubmitLoading(false);
  };

  // useEffect(() => {
  //   if (error || bankError) {
  //     ErrorHandler(error || bankError).forEach((e) => {
  //       CustomToast("error", e.message);
  //     });
  //   }
  // }, [error, bankError]);

  const clearProgress = () => {
    setProgressVisible(false);
    setProgress(0);
  };

  return (
    <div className="flex justify-center">
      {loading || (bankLoading && <Loader />)}
      {bank && role ? (
        <Formik
          initialValues={{
            paymentAmount: role?.fee,
            bank: "",
            paymentDate: moment(),
            paymentReferenceNo: "",
            document: [],
          }}
          onSubmit={(values) => {
            values.paymentAmount = role?.fee;
            handleRegister(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form className="flex my-5 flex-col justify-center items-center w-[90%] md:w-[70%] max-w-[600px] shadow-lg rounded-lg">
              {values.bank &&
                (() => {
                  const selectedBank =
                    bank[
                      bank.findIndex((banks) => banks?.name === values?.bank)
                    ];

                  return (
                    <div className="alert bg-info flex justify-center items-center rounded-bl-[0] rounded-br-[0]">
                      <BsFillInfoCircleFill size={20} color="white" />
                      <p className="text-center text-[whitesmoke]">
                        Pay{" "}
                        <span className="text-lg font-semibold">
                          {role?.fee}
                        </span>{" "}
                        ETB
                        {values.bank &&
                          " using " + selectedBank?.name + " account number "}
                        <span className="font-semibold">
                          {selectedBank?.accountNumber}
                        </span>
                      </p>
                    </div>
                  );
                })()}

              <FormDropDown
                data={bank}
                label={"Bank"}
                name={"bank"}
                width={"w-[90%] md:px-5 my-1"}
              />

              <FormField
                label="Payment Reference Number"
                name="paymentReferenceNo"
                type="text"
                placeholder="Payment Reference Number"
                width={"w-[90%] md:px-5 my-1"}
              />

              <FormDatePicker
                label={"Payment Date"}
                name={"paymentDate"}
                width={"w-[90%] md:px-5 my-1"}
              />
              <FormImage
                progress={progress}
                progressVisible={progressVisible}
                label="Education certificate"
                name="document"
                width={"w-[90%] md:px-5 my-1"}
              />

              <FormButton
                loading={submitLoading || progressVisible}
                title={"Upgrade"}
              />
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
}

export default SignUpForG3;

import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import React, { forwardRef, useContext, useState } from "react";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import jwtDecode from "jwt-decode";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import FormField from "../../components/CustomForm/FormField";
import CustomToast from "../../components/CustomToast";
import AuthContext from "../../context/AuthContext";
import { registerG1Service } from "../../services/userService";
import { setLocalStorageItem } from "../../helper/LocalStorage";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import { ErrorHandler } from "../../helper/ErrorHandler";
import { isValidPhoneNumber } from "react-phone-number-input";

const SignUp = forwardRef(
  ({ showModal, setShowModal, setShowModalForLogin }, ref) => {
    const validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .matches(/^(\S+$)/g, "Please enter only your first name")
        .label("First name"),
      phoneNumber: Yup.string().required().label("Phone number"),
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password"),
      reEnterPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    });

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const { setUser } = authContext;

    const handleSignup = async (values) => {
      setLoading(true);
      delete values.reEnterPassword;
      try {
        const { data, headers } = await registerG1Service(values);
        if (data) {
          setLocalStorageItem(headers["x-auth-token"]);
          const user = jwtDecode(headers["x-auth-token"]);
          if (user.id) setUser(user);
          setShowModal(false);
          setShowModalForLogin(false);
          navigate("/");
        }
      } catch (error) {
        ErrorHandler(error).map((e) => CustomToast("Err", e.message));
      }
      setLoading(false);
    };

    const handleChange = (e, setFieldValue, setFieldError, setFieldTouched) => {
      const pn = e?.toString();
      if (pn && !isValidPhoneNumber(pn)) {
        setFieldError("phoneNumber", "Please enter a valid phone number");
        setFieldTouched("phoneNumber", true, false);
      } else {
        setFieldError("phoneNumber", "");
        setValue(e);
        setFieldValue("phoneNumber", e, true);
      }
    };

    return (
      <div className="">
        {showModal && (
          <>
            <CustomModal
              onClose={() => setShowModal(false)}
              loading={loading}
              ref={ref}
              title={"Signup"}
            >
              <Formik
                initialValues={{
                  firstName: "",
                  phoneNumber: "",
                  email: "",
                  password: "",
                  reEnterPassword: "",
                }}
                onSubmit={(values) => {
                  handleSignup(values);
                }}
                validationSchema={validationSchema}
              >
                {({
                  isSubmitting,
                  values,
                  setFieldValue,
                  setFieldError,
                  setFieldTouched,
                }) => (
                  <Form className="flex flex-col flex-wrap w-[100%]  items-center rounded-lg">
                    <FormField
                      width="w-[100%] px-5 my-1"
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      style={{ width: "" }}
                    />
                    <FormField
                      width="w-[100%] px-5 my-1"
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      style={{ width: "" }}
                    />

                    <div className="w-[100%] px-5 my-1">
                      <p className="text-[#4D5959] text-[14px] md:text-[16px] text-left">
                        Phone Number
                      </p>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={value?.toString()}
                        name="phoneNumber"
                        style={{
                          padding: 10,
                          backgroundColor: "#EFF0F2",
                        }}
                        onChange={(e) =>
                          handleChange(
                            e?.toString(),
                            setFieldValue,
                            setFieldError,
                            setFieldTouched
                          )
                        }
                        className="my-2 w-[100%] outline-[#99d5e9] bg-[#EFF0F2] text-gray-900 rounded-lg  "
                      />

                      <ErrorMessage
                        className="text-[red]"
                        name={"phoneNumber"}
                        component="p"
                      />
                    </div>
                    <FormField
                      width="w-[100%] px-5 my-1"
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                    <FormField
                      width="w-[100%] px-5 my-1"
                      label="Re-Enter Password"
                      name="reEnterPassword"
                      type="password"
                      placeholder="Re-Enter Password"
                    />

                    <div className="flex flex-col justify-center my-5 items-center w-full">
                      <button
                        type="submit"
                        className={`bg-[#232536] px-10 py-2 xs:w-[90%] md:w-[40%] lg:w-[35%] max-w-[150px] rounded-lg text-white`}
                        // disabled={isSubmitting}
                      >
                        Sign up
                      </button>

                      <p
                        onClick={() => {
                          setShowModalForLogin(true);
                          setShowModal(false);
                        }}
                        className="text-[#232536] my-5"
                      >
                        Already have an Account? Login
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </CustomModal>
          </>
        )}
      </div>
    );
  }
);

export default SignUp;
